import React, {useState, useEffect, useRef, useMemo, useCallback, lazy, Suspense} from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {Popover, Chip, Menu, Autocomplete} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useParams} from "react-router-dom";
import Modal from "react-modal";
import "./CryptoPage.css"; // Assuming you have a CSS file for styling
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import {styled} from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import EmailPopUp from "./EmailPopUp.js";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import {BottomNavigation, Button} from "@mui/material";
import SidebarMenuList from "../includes/sidebarMenuList.jsx";

import IconButton from "@mui/material/IconButton";
import {Grid} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import {useLocation, Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import LoginPopUp from "./loginpopup";
import {Tabs, Tab} from "@mui/material";
import {getCryporCurrencyListHome} from "../apihelper/homepage";

import Dialog from "@mui/material/Dialog";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {Card, TextField, CardHeader, CardContent} from "@mui/material";

import {DialogTitle, DialogContent, DialogActions, Select} from "@mui/material"; // Import Material-UI components

import {addToPortfolio, copyToPortfolio} from "../apihelper/portfolio";

import CloseIcon from "@mui/icons-material/Close";

import CreatableSelect from "react-select/creatable";
import {getTotalunit, getFetchdropdown} from "../apihelper/cryptocurrencylist";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Footer from "../common/footer/Footer";
import MetaProperty from "../common/meta_property/MetaProperty";


const drawerWidth = 292;
const CryptoReplacement = (props) => {
    const {window1} = props;
    const crypto = "bitcoin";
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortType, setSortType] = useState("fd_asc");
    const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [loader, setLoader] = useState(false);
    const [slug, setSlug] = useState("BTC");
    const [tfhrs, settfhrs] = useState("");
    const [sevenDays, setSevenDays] = useState(false);
    const [thirtyDays, setThirtyDays] = useState(false);
    const [yearDays, setYearDays] = useState(false);
    const [buyPercent, setBuyPercent] = useState(false);
    const [holdings, setHoldings] = useState(false);
    const [description, setDescription] = useState("");
    const [symbolLogo, setSymbolLogo] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(false);
    const [marketCap, setMarketCap] = useState(false);
    const [rowsToShow, setRowsToShow] = useState(2);
    const [selectedSort, setSelectedSort] = useState("");
    const [hashtags, setHashtags] = useState(false);
    const sortRef = useRef();
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();
    const loc = location.pathname;
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [cryptoName, setCryptoName] = useState(false);
    const [sortOrder, setSortOrder] = useState({
        // Track ascending/descending for each column
        forecaster: "asc",
        target: "asc",
        accuracy: "asc",
        percent: "asc",
        targetDate: "asc",
    });
    const [isAtBottom, setIsAtBottom] = useState(false);


    // Render row based on the action color
    const renderAction = (action) => {
        switch (action) {
            case "Buy":
            case "Low":
            case "Bullish":
            case "bullish":
            case "Partially Bullish":
            case "Outperformer":
            case "Strong Buy":
                return <span style={{color: "green", fontWeight: ""}}> {capitalizeFirstLetter(action)}</span>;

            case "Sell":
            case "High":
            case "Bearish":
            case "Partially Bearish":
            case "Underperformer":
            case "Strong Sell":
                return <span style={{color: "red", fontWeight: ""}}> {capitalizeFirstLetter(action)}</span>;
            case "Neutral":
            
            case "Moderate Performer":
                return <span style={{color: "orange", fontWeight: ""}}>Neutral</span>;
            case "Hold":
                return <span style={{color: "orange", fontWeight: ""}}>Hold</span>;
            case "Medium":
                return <span style={{color: "orange", fontWeight: ""}}>Medium</span>;

            default:
                return null;
        }
    };

    function capitalizeFirstLetter(text) {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }

    const mediaQueryVar = useMediaQuery(
        json2mq({
            minWidth: 900,
        })
    );

    function formatDate(dateString) {
        if (!dateString) return ""; // Handle cases where dateString is undefined or null
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0"); // Add leading zero to day
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero to month (0-based index)
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const handleLoginClick = () => {

        const originalUrl = "/crypto-replacement";
        sessionStorage.setItem("originalUrl", originalUrl);
        // Mask the URL by adding /login at the end without navigating
        window.history.pushState({}, "", "/login");
        localStorage.setItem("isPopupManuallyOpened", "true");
        // Open the login modal
        setLoginModalOpen(true);
    };
    const handleModalClose = () => {
        // Close the modal and reset the URL to the welcome page
        setLoginModalOpen(false);
        navigate(location.pathname, {replace: true});
    };

    const mediaQueryVar1 = useMediaQuery(
        json2mq({
            minWidth: 400,
        })
    );

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            border: 0,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: 0,
        },
    }));

    const StyledTableRow = styled(TableRow)(({theme}) => ({
        // hide last border

        "& td, & th": {
            border: 0,
        },
    }));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div className="d-flex flex-column h-100" style={{overflowY: "hidden"}}>
            <SidebarMenuList />
        </div>
    );

    const [predictionLoader, setPredictionLoader] = useState(false);
    const [oscillatorData, setOscillatorData] = useState(false);
    const [movingAveragesData, setMovingAveragesData] = useState(false);
    const [timestamp, setTimestamp] = useState("");
    const [pivotdata, setpivotdata] = useState("");
    const [evaluation_data, setevaluation_data] = useState("");
    const [analysis_data, setanalysis_data] = useState("");
    const [outlookData, setOutlookData] = useState("");
    const [paid_member, setpaid_member] = useState("no");
    const [currentCrypto, setCurrentCrypto] = useState();
    const [category, setCategory] = useState();

    const handleNavigation = () => {
        const userData = localStorage.getItem("cw_portfolio_user");
        if (!userData) {
            const originalUrl = "/subscriptions/screener360";
            sessionStorage.setItem("originalUrl", originalUrl);
            window.history.pushState({}, "", "/login");
            localStorage.setItem("isPopupManuallyOpened", "true");

            // Open the login modal
            setLoginModalOpen(true);
            //window.location.href = `${process.env.REACT_APP_HOME_URL}subscriptions/screener360`;

            return;
        } else if (userData && paid_member == "no") {
            window.location.href = `${process.env.REACT_APP_HOME_URL}subscriptions/screener360`;
        }
    };
    const [votes, setVotes] = useState({ bullish: 0, bearish: 0 });
    const [totalVotes, setTotalVotes] = useState(0);
    const [showPercentage, setShowPercentage] = useState(false);

  

    const handleSentimentClick = async (type) => {
        const lastVoteTime = localStorage.getItem(`lastVoteTime_${slug}`);
      
        if (lastVoteTime) {
            const timeDifference = Date.now() - parseInt(lastVoteTime, 10);
            if (timeDifference < 24 * 60 * 60 * 1000) {
                setShowPercentage(true);
                alert(`You have already voted for ${slug}. You can vote again in 24 hours.`);
                return;
            }
        }
    
        try {
            // Send vote to WordPress API
            const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/updateCryptoVotes', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ symbol: slug, type }),
            });
    
            const data = await response.json();
            console.log("doge response:",data)
    
            if (data.error) {
                alert(data.error);
                return;
            }
    
            
            setVotes({ bullish: data.bullish_percentage, bearish: data.bearish_percentage });
            setShowPercentage(true);
    
            // Store vote timestamp in localStorage
            localStorage.setItem(`lastVoteTime_${slug}`, Date.now().toString());
        } catch (error) {
            console.error("Error submitting vote:", error);
            alert("Something went wrong. Please try again later.");
        }
    };
    
    const calculatePercentage = (type) => {
    
        const votePercentage = type === "bullish" ? votes.bullish : votes.bearish;
        return votePercentage;
    };
    
    const [hashtags2, setNewHashtags] = useState(false);
    const [risk, setRisk] = useState(null);
    const [top100Holders, setTop100Holders] = useState(null);   

    const [cryptos, setCryptos] = useState([]);
const [selectedCrypto, setSelectedCrypto] = useState("Choose Crypto");
const [selectedCryptoName, setSelectedCryptoName] = useState("bitcoin"); // Store name
const [sortedSlugdata, setSortedSlugData] = useState([]);
const [fileReplacement, setFileReplacement] = useState(false);

useEffect(() => {

    const fetchCryptos = async () => {
        try {
            const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/getCryptoSymbolName");
            const data = await response.json();
            if (response.ok) {
                setCryptos(data.data); // Store symbol-name pairs
            }
        } catch (error) {
            console.error("Error fetching cryptos:", error);
        }
    };

    fetchCryptos();
}, []);



    useEffect(() => {
        if (!selectedCryptoName) return;
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const userId = userData?.user_id; // Use optional chaining to safely access user_id
        console.log("Selected crypto:", selectedCrypto, "Name:", selectedCryptoName);

        const fetchData = async () => {
            try {
                const response = await fetch(
                    "https://crowdwisdom.live/wp-json/api/v1/showForecasterFutureDataInvesting",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            searchVal: selectedCryptoName, // Adjust if necessary
                            sortType: sortType,
                            userId: userId || null, // Send null if userId is not available
                        }),
                    }
                );

                const result = await response.json();
                setCurrentCrypto(result);
                if (result.code === "200") {
                    if (result.response.length === 0) {
                        setPredictionLoader(true);
                    }
                    console.log("response",result);
                    setLoader(true);
                    setSlug(result.symbol);
                    setCurrentPrice(result.current_price);
                    setSymbolLogo(result.symbol_logo);
                    settfhrs(result.tfhrs);
                    setSevenDays(result.last_7_days);
                    setThirtyDays(result.last_30_days);
                    setYearDays(result.last_365_days);
                    setBuyPercent(result.buy_percent);
                    setHoldings(result.holdings);
                    setDescription(result.crypto_desc);
                    setMarketCap(result.market_cap);
                    setData(result.response);
                    setpaid_member(result.paid_member);
                    setCryptoName(result.crypto_name);
                    setRisk(result.risk); // Set the risk value
                    setFileReplacement(false);
                } else {
                    setError(result.message);
                }
            } catch (err) {
                setError("An error occurred while fetching data.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [selectedCryptoName, sortType]);

   
    
    
    const handleFindReplacement = async () => {
        try {
          const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/get_better_performers", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ crypto: slug }), // Send selected crypto
          });
    
          const data = await response.json();
          setSortedSlugData(data); // Store response in state
          setFileReplacement(true);
          console.log("Updated sortedSlugData:", data);
        } catch (error) {
          console.error("Error fetching better performers:", error);
        }
      };

    const container = window1 !== undefined ? () => window1().document.body : undefined;
    useEffect(() => {
        if (typeof window !== "undefined") {
            const path = window.location.pathname;
            const extractedSlug = path.split("/").pop(); // Get the last part of the URL
            setSlug(extractedSlug);
        }
    }, []);

    
    useEffect(() => {
        if (slug) {
            handleFindReplacement();
        }
    }, [slug]); // Runs whenever `loader` becomes true
    
    
    




 


    
    const sanitizedDescription = description;
    const mediaQueryVar2 = useMediaQuery(
        json2mq({
            maxWidth: 420,
        })
    );
    const mediaQueryVar3 = useMediaQuery(
        json2mq({
            minWidth: 375,
        })
    );

    // Function to extract indentation level and style accordingly
    const getIndentationStyle = (htmlString) => {
        const div = document.createElement("div");
        div.innerHTML = htmlString;

        const items = div.querySelectorAll("li");
        items.forEach((item) => {
            const match = item.className.match(/ql-indent-(\d+)/);
            if (match) {
                const level = parseInt(match[1], 10);
                // Set padding based on indent level for content, reducing by 10px
                item.style.paddingLeft = `${20 * level - 20}px`; // Adjusted padding
                // Adjust margin for bullet alignment
                item.style.marginLeft = `${40 * level}px`; // Bullet alignment
            }
        });

        return div.innerHTML; // Return modified HTML
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sortRef.current && !sortRef.current.contains(event.target)) {
                setSortDropdownVisible(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    

    

    const closeModal = () => {
        setModalIsOpen(false);
        setModalData(null);
    };

    const filteredData = data;

    const handleLoadMore = () => {
        navigate(`/crypto/${crypto}/predictions`);
        window.scrollTo({top: 0, behavior: "smooth"});
    };

    const [isTableView, setIsTableView] = useState(true);
    const [selectedButton, setSelectedButton] = useState("CoinPrices");

    const handleButtonClick = (buttonName) => {
        // If the same button is clicked again, don't toggle the view
        if (selectedButton === buttonName) {
            return;
        }

        // Toggle the view based on the clicked button
        setSelectedButton(buttonName);
        setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
    };

    const parseDate = (dateStr) => {
        const [month, day, year] = dateStr.split(" ");
        const monthMap = {
            Jan: "01",
            Feb: "02",
            Mar: "03",
            Apr: "04",
            May: "05",
            Jun: "06",
            Jul: "07",
            Aug: "08",
            Sep: "09",
            Oct: "10",
            Nov: "11",
            Dec: "12",
        };
        const formattedDate = `${year}-${monthMap[month]}-${day.padStart(2, "0")}`;
        return new Date(formattedDate);
    };

    const parseForecastPrice = (price) => {
        return parseFloat(price.replace(/[^0-9.-]+/g, ""));
    };

    const parseAccuracy = (accuracy) => {
        if (typeof accuracy !== "string") return NaN; // Return NaN if input is not a string

        // Extract the number from the accuracy string
        const percentage = accuracy.split("%")[0].trim(); // Get the part before '%'
        const parsedValue = parseFloat(percentage.split(" ")[0]); // Convert to float

        return isNaN(parsedValue) ? 0 : parsedValue; // Return 0 for NaN for safety
    };

    const compareValues = (val1, val2, order = "asc") => {
        // If either value is NaN, treat it as less than any valid number
        if (isNaN(val1) && isNaN(val2)) return 0; // Both are NaN
        if (isNaN(val1)) return 1; // Treat NaN as the largest value
        if (isNaN(val2)) return -1; // Treat NaN as the largest value

        // Perform comparison based on order
        if (order === "asc") return val1 - val2;
        return val2 - val1;
    };
    var rows = [],
        i = 0,
        len = 4;
    while (++i <= len) rows.push(i);

    const handleSort = (column) => {
        const newSortOrder = sortOrder[column] === "asc" ? "desc" : "asc"; // Toggle sort order
        setSortOrder({...sortOrder, [column]: newSortOrder});

        // Set the sort type based on the column
        switch (column) {
            case "forecaster":
                setSortType(newSortOrder === "asc" ? "fc_asc" : "fc_desc");
                break;
            case "target":
                setSortType(newSortOrder === "asc" ? "tg_asc" : "tg_desc");
                break;
            case "accuracy":
                setSortType(newSortOrder === "asc" ? "ac_asc" : "ac_desc");
                break;
            case "percent":
                setSortType(newSortOrder === "asc" ? "pr_asc" : "pr_desc");
                break;
            case "targetDate":
                setSortType(newSortOrder === "asc" ? "fd_asc" : "fd_desc");
                break;
            default:
                break;
        }
    };

    const sortedData = [...filteredData].sort((a, b) => {
        switch (sortType) {
            case "fc_asc":
                return a.forecaster_name.localeCompare(b.forecaster_name);
            case "fc_desc":
                return b.forecaster_name.localeCompare(a.forecaster_name);
            case "tg_asc":
                return parseForecastPrice(a.forecast_price) - parseForecastPrice(b.forecast_price);
            case "tg_desc":
                return parseForecastPrice(b.forecast_price) - parseForecastPrice(a.forecast_price);
            case "ac_asc": {
                const accuracyA = parseAccuracy(a.accuracy);
                const accuracyB = parseAccuracy(b.accuracy);
                return compareValues(accuracyA, accuracyB, "asc");
            }
            case "ac_desc": {
                const accuracyA = parseAccuracy(a.accuracy);
                const accuracyB = parseAccuracy(b.accuracy);
                return compareValues(accuracyA, accuracyB, "desc");
            }
            case "pr_asc":
                return parseFloat(a.target_percent) - parseFloat(b.target_percent);
            case "pr_desc":
                return parseFloat(b.target_percent) - parseFloat(a.target_percent);
            case "fd_asc":
                return parseDate(a.target_date) - parseDate(b.target_date);
            case "fd_desc":
                return parseDate(b.target_date) - parseDate(a.target_date);
            default:
                return 0;
        }
    });

    const [isPredictionDataLoaded, setIsPredictionDataLoaded] = useState(false);
    useEffect(() => {
        if (sortedData && sortedData.length > 0) {
            setIsPredictionDataLoaded(true); // Set to true when data is available
        }
    }, [sortedData]);

    const countEvaluations = (data, evaluationKeys) => {
        let buys = 0,
            sells = 0,
            neutrals = 0;

        data.forEach((item) => {
            evaluationKeys.forEach((key) => {
                if (item[key] === "Buy") buys += 1;
                else if (item[key] === "Sell") sells += 1;
                else if (item[key] === "Neutral") neutrals += 1;
            });
        });

        return {buys, sells, neutrals};
    };

    const handleTabClick = () => {
        navigate("/predict-now", {state: {cryptoValue: "btc"}});
    };

    const oscillatorEvaluationKeys = [
        "rsi_evaluation",
        "cci_evaluation",
        "ao_evaluation",
        "mom_evaluation",
        "macd_evaluation",
        "stochrsi_evaluation",
        "willr_evaluation",
        "ultosc_evaluation",
    ];

    const movingAverageEvaluationKeys = [
        "sma_10_evaluation",
        "sma_50_evaluation",
        "sma_100_evaluation",
        "sma_200_evaluation",
        "ema_10_evaluation",
        "ema_50_evaluation",
        "ema_100_evaluation",
        "ema_200_evaluation",
    ];

    const isDataLoaded = oscillatorData && movingAveragesData;

    const oscillatorCounts = isDataLoaded
        ? countEvaluations(oscillatorData, oscillatorEvaluationKeys)
        : {buys: 0, sells: 0, neutrals: 0};

    const movingAverageCounts = isDataLoaded
        ? countEvaluations(movingAveragesData, movingAverageEvaluationKeys)
        : {buys: 0, sells: 0, neutrals: 0};

    const {buys: oscillatorBuys, sells: oscillatorSells, neutrals: oscillatorNeutrals} = oscillatorCounts;
    const {buys: movingAverageBuys, sells: movingAverageSells, neutrals: movingAverageNeutrals} = movingAverageCounts;

    const getSummary = (buys, sells) => {
        if (buys > sells) return "Strong Buy";
        if (sells > buys) return "Strong Sell";
        return "Neutral";
    };

    const oscillatorSummary = isDataLoaded ? getSummary(oscillatorBuys, oscillatorSells) : "-";
    const movingAverageSummary = isDataLoaded ? getSummary(movingAverageBuys, movingAverageSells) : "-";

    const getOverallSummary = () => {
        if (oscillatorSummary === "Strong Buy" && movingAverageSummary === "Strong Buy") return "Bullish";
        if (oscillatorSummary === "Strong Sell" && movingAverageSummary === "Strong Sell") return "Bearish";
        if (oscillatorSummary === "Strong Buy" || movingAverageSummary === "Strong Buy") return "Partially Bullish";
        if (oscillatorSummary === "Strong Sell" || movingAverageSummary === "Strong Sell") return "Partially Bearish";
        if (!oscillatorSummary || !movingAverageSummary) return "Neutral";
        return "";
    };

    const overallSummary = isDataLoaded ? getOverallSummary() : "-";

    // Check if any data is available for oscillators or moving averages
    const hasOscillatorData = oscillatorBuys + oscillatorSells + oscillatorNeutrals > 0;
    const hasMovingAverageData = movingAverageBuys + movingAverageSells + movingAverageNeutrals > 0;

    // Define statements based on sentiment
    const bullish =
        `A breakout above its initial resistance is likely to draw in more buyers. ` +
        `The next level of resistance at ${pivotdata.pivot_r2} may be retested or surpassed if buying pressure intensifies. `;

    const bearish =
        `If ${slug} fails to hold above its first level of support, ${pivotdata.pivot_s2} ` +
        `will be the immediate support. `;

    const neutral =
        `${slug} is likely to trade in the range between ${pivotdata.pivot_s1} and ${pivotdata.pivot_r1} ` + `for now.`;

    // Main statement based on overall sentiment
    const sentimentStatement =
        overallSummary === "Bullish" || overallSummary === "Partially Bullish"
            ? bullish
            : overallSummary === "Bearish" || overallSummary === "Partially Bearish"
            ? bearish
            : neutral;

    const [data_replacements, setData_replacements] = useState([]);
    const [loading_replacements, setLoading_replacements] = useState(true);
    const [predictionLoader_replacements, setPredictionLoader_replacements] = useState(false);
    const [isPredictionDataLoaded_replacements, setIsPredictionDataLoaded_replacements] = useState(false);
    useEffect(() => {
        const fetchCryptoData = async () => {
            try {
                const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/showCryptoReplacements", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        crypto: crypto,
                        sortType: sortType,
                    }),
                });

                const result = await response.json();
                if (result.code === "200") {
                    setData_replacements(result.response);
                    if (result.response.length === 0) {
                        setPredictionLoader_replacements(true);
                    }
                } else {
                    setError(result.message);
                }
            } catch (err) {
                setError("An error occurred while fetching data.");
            } finally {
                setLoading_replacements(false);
            }
        };

        fetchCryptoData();
    }, [sortType, crypto]);

    const [showModal, setShowModal] = useState(false); // Change the state name to showModal

    const filteredData1 = data_replacements;

    const sortedData1 = [...filteredData1].sort((a, b) => {
        switch (sortType) {
            case "ac_asc":
                return compareValues(a.accuracy, b.accuracy, "asc");
            case "ac_desc":
                return compareValues(a.accuracy, b.accuracy, "desc");
            default:
                return 0;
        }
    });

    const [ignoredRows, setIgnoredRows] = useState([]);

    const [open, setOpen] = useState(false);
    const fetchDropdownOptions = async () => {
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

        getFetchdropdown({
            user_id: userData.user_id,

            Token: userData.token,
        }).then((res) => {
            if (res.code === "200") {
                const newOptions = res.data.map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
                setOptions(newOptions);
                setuser_role(res.user_role);
            } else {
                console.error("Data fething error");
            }
        });
    };

    const handleIgnoreClick = (index) => {
        const updatedIgnoredRows = [...ignoredRows, index];
        setIgnoredRows(updatedIgnoredRows);

        // Check if all currently displayed rows are ignored
        const allIgnored = sortedData1.slice(0, rowsToShow).every((_, idx) => updatedIgnoredRows.includes(idx));

        // If all rows are ignored, call handleLoadMore
        if (allIgnored) {
            handleLoadMore();
        }
    };

    const allRowsIgnored = sortedData1.slice(0, rowsToShow).every((_, index) => ignoredRows.includes(index));

    useEffect(() => {
        if (sortedData1 && sortedData1.length > 0) {
            setIsPredictionDataLoaded_replacements(true); // Set to true when data is available
        }
    }, [sortedData1]);

    const [unit, setUnit] = useState("");
    const [selectedOption, setSelectedOption] = useState([]);
    const [price, setPrice] = useState("");
    const [totalUnits, setTotalUnits] = useState(0);
    const [selectedDate, handleDateChange] = useState("");
    const [Usablewallet, setUsablewallet] = useState("");
    const [portfolioTypeId, setPortfolioTypeId] = useState("");
    const handleChange = async (selectedOption, slug) => {
        if (selectedOption && selectedOption._isNew_) {
            setIsDialogOpen(true);
            setSelectedOption(null);
            setdataslug(slug);
        } else if (!selectedOption) {
            // Handle the case when the selection is cleared
            setSelectedOption(null);
            setShowAlert(false);
            // Perform any other necessary actions
            return;
        } else {
            const selectedValue = selectedOption.value;
            if (selectedValue.includes("-")) {
                setErrorMessage("Hyphens are not allowed in the option."); // Set error message
                setShowAlert(true); // Show the alert
                return; // Abort further processing
            }
            setShowAlert(false);
            setSelectedOption(selectedOption);
            const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
            getTotalunit({
                user_id: userData.user_id,
                slug: slug, // Assuming value contains the slug
                portfolio_type_id: selectedOption.value, // Adjust as needed
                Token: userData.token,
            }).then((res) => {
                if (res.code === "200") {
                    setTotalUnits(res.total_uint);
                    setUsablewallet(res.wallet_amount);
                } else {
                    console.error("Data fething error");
                }
            });
        }
    };
    useEffect(() => {
        if (open || showModal) {
            fetchDropdownOptions();
        }
    }, [open, showModal]);
    const handleCreate = async (inputValue) => {
        setTotalUnits(0);
        setUsablewallet("");

        if (options.find((option) => option.value === inputValue)) {
            return; // Exit early if the option already exists
        }
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const newOption = {value: inputValue, label: inputValue};
        const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
        setOptions(newOptions); // Update the options state
        const createdOption = newOptions.find((option) => option.value == inputValue);
        if (createdOption) {
            handleChange(createdOption);
        }
    };
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const formatToNonZero = (number) => {
        number = parseFloat(number);

        if (number >= 1) {
            return number.toFixed(2);
        } else if (number >= 0.1) {
            return number.toFixed(1);
        } else if (number >= 0.01) {
            return number.toFixed(4);
        } else if (number >= 0.001) {
            return number.toFixed(5);
        } else if (number >= 0.0001) {
            return number.toFixed(6);
        } else if (number >= 0.00001) {
            return number.toFixed(7);
        } else if (number >= 0.000001) {
            return number.toFixed(8);
        } else if (number >= 0.0000001) {
            return number.toFixed(9);
        } else if (number >= 0.00000001) {
            return number.toFixed(10);
        } else if (number >= 0.000000001) {
            return number.toFixed(11);
        }
        return number.toFixed(1);
    };
    const [buySell, setbuySell] = React.useState("");
    const handleClick = (buySell) => {
        setSnackbaropen(true);
        setOpen(true);
        setSeverity("info");
        setIsFormSubmitted(false);
        // setSnackbarcolor("success");
        // setSnackbarmessage("Data saved successfully");
        setMsgforUpdatePortfolio("loading...");
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const portfolioId =
            selectedOption && !isNaN(parseInt(selectedOption.value)) ? parseInt(selectedOption.value) : 0;
        const existingPortfolio = portfolioId > 0 ? "yes" : "no"; // Determine existing_portfolio based on portfolioId

        addToPortfolio({
            portfolio_type_id: portfolioId,
            portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined

            existing_portfolio: existingPortfolio,
            user_id: userData.user_id,
            slug: slugpopupdata.slugname,
            unit: unit,
            acquisition_price: price,
            date_time: selectedDate,
            type: buySell,
            Token: userData.token,
        }).then((res) => {
            if (res.code === "200") {
                // setOpen(false);
                setSeverity("info");
                setMsgforUpdatePortfolio(res.message);
                setPortfolioTypeId(res.portfolio_type_id);
                setIsFormSubmitted(true);
                // navigate(
                //   `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
                //     selectedOption.label
                //   )}`
                // );
            } else {
                setSeverity("error");
                // setOpen(true)
                setMsgforUpdatePortfolio(res.message);
            }
        });
    };
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newOptionValue, setNewOptionValue] = useState("");
    const handleDialogSubmit = () => {
        // Handle submitting the dialog (e.g., creating the new option)

        // Update options state with the new option
        const newOption = {value: newOptionValue, label: newOptionValue};
        setOptions([...options, newOption]);
        setSelectedOption(newOption);
        // Close the dialog
        setIsDialogOpen(false);

        // setSelectedOption(selectedOption);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        getTotalunit({
            user_id: userData.user_id,
            slug: dataslug, // Assuming value contains the slug
            portfolio_type_id: newOption.value, // Adjust as needed
            Token: userData.token,
        }).then((res) => {
            if (res.code === "200") {
                setTotalUnits(res.total_uint);
                setNewOptionValue("");
                setUsablewallet(res.wallet_amount);
            } else {
                console.error("Data fething error");
            }
        });
    };
    const [snackbaropen, setSnackbaropen] = React.useState(false);
    const closesnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbaropen(false);
    };
    const [severity, setSeverity] = useState("info");
    const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] = useState("loading...");
    const handleClose = () => setOpen(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [dataslug, setdataslug] = useState("");
    const [options, setOptions] = useState([]);
    const handleChange2 = (e) => {
        const value = e.target.value;
        // Check if the input contains a hyphen
        if (value.includes("-")) {
            setShowAlert(true); // Show the alert if there's a hyphen
        } else {
            setShowAlert(false); // Hide the alert if there's no hyphen
            setNewOptionValue(value); // Update the state with the new value
        }
    };
    const [slugdata, setSlugdata] = useState([]);
    const [user_role, setuser_role] = useState("");
    const [slugpopupdata, setSlugpopupdata] = useState({
        slugname: "",
        slug: "",
        units: 0,
    });

    const handleAddToPortfolio = (idx) => {

        getCryporCurrencyListHome({
            slug: idx,
        }).then((res) => {
            if (res.code === "200") {

                // Update slugdata
                setSlugdata(res.data);
                setuser_role(res.user_role);

                // Use res.data directly here
                const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
                if (userData) {
                    setSlugpopupdata({
                        slugname: res.data[0].slug,
                        slug: res.data[0].name,
                        units: res.data[0].total_unit,
                        pricetoday: res.data[0].price_today,
                        logo: res.data[0].logo,
                    });
                    setOpen(true);
                } else {
                    // Save the source in sessionStorage
                    const originalUrl = window.location.pathname + window.location.search;
                    sessionStorage.setItem("originalUrl", originalUrl);
                    sessionStorage.setItem("loginSource", "addToPortfolio");
                    window.history.pushState({}, "", "/login");
                    localStorage.setItem("isPopupManuallyOpened", "true");
                    setLoginModalOpen(true);
                }
            } else {
                console.error("Data fetching error");
            }
        });
    };

    const [selectedFilter, setSelectedFilter] = useState(null);
    const handleClickROIHL = () => {
        setSortType("roi_desc");
    };
    const handleClickROILH = () => {
        setSortType("roi_asc");
    };
    const handleClearAll = () => {
        setSortType("roi_asc");
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const openfilter = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseeoption = () => {
        setAnchorEl(null);
    };

 
  
  


    return (
        <div
            className="crypto-page"
            style={{
                padding: mediaQueryVar ? 0 : "10px",
                margin: 0,
                backgroundColor: mediaQueryVar ? "#F2F2F2" : "#fff",
            }}
        >
            <MetaProperty
                title="Find Replacement"
                description={`Accurate ${
                    selectedCrypto.charAt(0).toUpperCase() + selectedCrypto.slice(1)
                } Influencer Price Predictions & In-depth ${
                    selectedCrypto.charAt(0).toUpperCase() + selectedCrypto.slice(1)
                } Price Analysis.`}
                logo={currentCrypto?.symbol_logo}
            />

            <Modal
                isOpen={open}
                onRequestClose={handleClose}
                overlayClassName={{
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
                        backdropFilter: "blur(5px)", // Optional: Add a blur effect to the overlay
                    },
                }}
                className={{
                    content: {
                        position: "relative",
                        backgroundColor: "white", // Set background for inner popup
                        padding: "20px", // Add padding for content
                        borderRadius: "8px", // Rounded corners
                        maxWidth: mediaQueryVar ? "100%" : "100%", // Set a max width for popup
                        margin: "0", // Center the popup horizontally
                        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)", // Optional: Popup shadow
                    },
                }}
            >
                <div className="CardOpener" style={{overflow: "hidden"}}>
                    {isFormSubmitted ? (
                        <div
                            style={{
                                position: "fixed", // Fix the popup to the top of the viewport
                                top: 0, // Align it to the top
                                left: 0,
                                width: "100%",
                                height: "100vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: darken the background for emphasis
                                zIndex: 1000, // High z-index to overlay other elements
                            }}
                        >
                            <Card className="card__container">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <CheckCircleOutlineIcon sx={{fontSize: 50, mr: 1}} />
                                    </div>
                                    <Typography
                                        severity="success"
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        Portfolio added successfully!
                                    </Typography>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            marginTop: "10px",
                                            width: "100%",
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                setIsFormSubmitted(false);
                                                setOpen(false);
                                                setUnit("");
                                                setSelectedOption("");
                                                setPrice("");
                                                setTotalUnits(0);
                                                handleDateChange("");
                                                setUsablewallet("");
                                            }}
                                            color="primary"
                                            style={{
                                                backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                borderRadius: "6px",
                                                border: "none",
                                                textTransform: "none",
                                                borderStyle: "solid",
                                                borderColor: "rgba(67, 97, 238, 0.15)",
                                                borderWidth: "1px",
                                                color: "#4361ee",
                                                whiteSpace: "nowrap",
                                                float: "inline-end",
                                                width: mediaQueryVar === true ? "150px" : "100px",
                                                height: mediaQueryVar === false ? "30px" : "",
                                                fontSize: mediaQueryVar === false ? "10px" : "11px",
                                            }}
                                        >
                                            Add More Cryptos
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                navigate(
                                                    `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                                                        selectedOption.label.replace(/\s+/g, "-")
                                                    )}`
                                                )
                                            }
                                            variant="outlined"
                                            style={{
                                                backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                borderRadius: "6px",
                                                border: "none",
                                                textTransform: "none",
                                                borderStyle: "solid",
                                                borderColor: "rgba(67, 97, 238, 0.15)",
                                                borderWidth: "1px",
                                                color: "#4361ee",
                                                whiteSpace: "nowrap",
                                                float: "inline-end",
                                                width: mediaQueryVar === true ? "100px" : "100px",
                                                height: mediaQueryVar === false ? "30px" : "",
                                                fontSize: mediaQueryVar === false ? "10px" : "11px",
                                            }}
                                            color="primary"
                                        >
                                            View Portfolio
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    ) : (
                        <Dialog
                            PaperProps={{
                                sx: {
                                    position: mediaQueryVar ? "static" : "fixed",
                                    bottom: mediaQueryVar ? "" : 0,
                                    left: mediaQueryVar ? "" : 0,
                                    right: mediaQueryVar ? "" : 0,
                                    marginLeft: mediaQueryVar ? "" : "0px",
                                    marginRight: mediaQueryVar ? "" : "0px",
                                    marginTop: "1%",
                                    maxWidth: "100%",
                                    minHeight: mediaQueryVar ? "60%" : "", // Adjust max height if needed
                                    borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
                                    marginBottom: mediaQueryVar ? "" : "0px",
                                    padding: mediaQueryVar ? "10px" : "1px",
                                    alignItems: "center",
                                    overflow: "scroll",
                                    "&::-webkit-scrollbar": {
                                        display: "none",
                                    },
                                    "-ms-overflow-style": "none",
                                    "scrollbar-width": "none", // Ensure no scrollbar
                                },
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <div
                                style={{
                                    padding: "20px",
                                    width: mediaQueryVar ? "400px" : "100%",
                                    margin: "0",
                                    marginTop: "-5px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "20px",
                                        borderBottom: "1px #E3E3E3 solid",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: "500",
                                            fontSize: "20.767px",
                                            paddingLeft: "100px",
                                        }}
                                        gutterBottom
                                    >
                                        Add To Portfolio
                                    </Typography>

                                    <div className="primaryButton">
                                        <img
                                            className="frameIcon"
                                            alt="frameIcon"
                                            onClick={handleClose}
                                            src={window.constants.asset_path + "/images/frame17.svg"}
                                            style={{
                                                background: "#F3F3F3",
                                                width: "25px",
                                                height: "25px",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginBottom: "5px",
                                        marginLeft: mediaQueryVar === false ? "10px" : "",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontSize: "13.845px",
                                            fontWeight: "500",
                                            marginBottom: "1px",
                                            color: "black",
                                            marginTop: "-8px",
                                        }}
                                    >
                                        Select Portfolio
                                    </Typography>
                                    <div style={{marginTop: "5px"}}>
                                        <CreatableSelect
                                            isClearable
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: "12px",
                                                    border: "1px solid #1877F2",
                                                    boxShadow: "none",
                                                    minHeight: "40px",
                                                    "&:hover": {
                                                        borderRadius: "12px",
                                                        border: "2px solid #1877F2",
                                                    },
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    borderRadius: "8px",
                                                }),
                                                placeholder: (provided) => ({
                                                    ...provided,
                                                    color: "",
                                                }),
                                                dropdownIndicator: (provided) => ({
                                                    ...provided,
                                                    color: "#1877F2",
                                                    border: "1px solid #1877F2",
                                                    padding: "5px",
                                                    margin: "8px",
                                                    borderRadius: "3.786px",
                                                    "&:hover": {
                                                        color: "#1877F2",
                                                    },
                                                }),
                                                indicatorSeparator: (provided) => ({
                                                    display: "none",
                                                }),
                                            }}
                                            placeholder=""
                                            onChange={(options) => handleChange(options, slugpopupdata.slugname)}
                                            onCreateOption={handleCreate}
                                            options={[
                                                {
                                                    value: "",
                                                    label: "Create new portfolio",
                                                    _isNew_: true,
                                                }, // Dynamic "create new option" item
                                                ...options, // Existing options
                                            ]}
                                            value={selectedOption}
                                        />
                                    </div>
                                    {showAlert && (
                                        <Alert severity="error" sx={{marginBottom: "10px"}}>
                                            {errorMessage}
                                        </Alert>
                                    )}
                                </div>
                                <Typography
                                    style={{
                                        fontSize: "13.845px",
                                        fontWeight: "500",
                                        color: "#000",
                                        marginTop: "10px",
                                        marginBottom: "5px",
                                    }}
                                >
                                    Usable Wallet Value: {Usablewallet}
                                </Typography>
                                <div
                                    style={{
                                        padding: mediaQueryVar ? "8px" : "5px",
                                        background: "#F3F3F3",
                                        borderRadius: "8px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "5px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flex: 0,
                                            minWidth: 0,
                                        }}
                                    >
                                        <img
                                            style={{
                                                marginRight: "10px",
                                                border: "2px solid #000",
                                                borderRadius: "19px",
                                                height: "27.69px",
                                                width: "27.69px",
                                            }}
                                            alt="logo"
                                            src={slugpopupdata.logo}
                                        />
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <Typography
                                                sx={{
                                                    fontWeight: "500",
                                                    marginBottom: "0px",
                                                    fontSize: "14px",
                                                    color: "#7F7F80",
                                                }}
                                            >
                                                {slugpopupdata.slugname}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginLeft: "20%",
                                            flex: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "500",
                                                marginBottom: "4px",
                                                fontSize: "13px",
                                                color: "#7F7F80",
                                            }}
                                        >
                                            {" "}
                                            Price
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12.114px",
                                                fontWeight: "500",
                                                color: "black",
                                                margin: "0",
                                                marginLeft: "5%",
                                            }}
                                        >
                                            {slugpopupdata.pricetoday}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginLeft: "20px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "500",
                                                marginBottom: "4px",
                                                fontSize: "13px",
                                                color: "#7F7F80",
                                            }}
                                        >
                                            {" "}
                                            Units
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12.114px",
                                                fontWeight: "500",
                                                color: "black",
                                                margin: "0",
                                            }}
                                        >
                                            {totalUnits}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="card__container__row3">
                                    <div style={{marginLeft: "-5px"}}>
                                        <p style={{fontSize: "14px", fontWeight: "500"}}>Enter number of Units</p>
                                        <input
                                            style={{
                                                backgroundColor: "white",
                                                color: "black",
                                                padding: "20px",
                                                border: "1px solid #1877F2",
                                                borderRadius: "10.384px",
                                                width: mediaQueryVar ? "350px" : "100%",
                                                height: "45px",
                                                margin: 0,
                                            }}
                                            min="1"
                                            max="10"
                                            type="number"
                                            placeholder=""
                                            value={unit}
                                            onChange={(e) => setUnit(e.target.value)}
                                        ></input>
                                    </div>
                                    <div>
                                        $
                                        {formatToNonZero(
                                            unit *
                                                parseFloat(
                                                    (slugpopupdata.pricetoday || "")
                                                    .replace(/^\$/, "") // Remove the dollar sign
                                                    .replace(/,/g, "") // Remove commas
                                                )
                                        )}
                                    </div>
                                    {user_role === "superuser" && (
                                        <>
                                            <div className="card__container__row3__r2">
                                                <div style={{marginLeft: "-5px"}}>
                                                    <p style={{fontSize: "14px", fontWeight: "500"}}>
                                                        Buy/Sell Price($)
                                                    </p>
                                                    <input
                                                        style={{
                                                            backgroundColor: "white",
                                                            color: "black",
                                                            padding: "20px",
                                                            border: "1px solid #1877F2",
                                                            borderRadius: "10.384px",
                                                            width: "345px",
                                                            height: "45px",
                                                        }}
                                                        className="card__container__row3__r2__input"
                                                        min="1"
                                                        max="10"
                                                        type="number"
                                                        placeholder="Enter Price"
                                                        value={price}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div style={{marginBottom: "10px", marginLeft: "-5px"}}>
                                                <p style={{fontSize: "14px", fontWeight: "500"}}>Date</p>
                                                <TextField
                                                    type="date"
                                                    value={selectedDate}
                                                    onChange={(e) => handleDateChange(e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            borderRadius: "10.384px",
                                                            border: "1px solid #1877F2",
                                                            boxShadow: "none",
                                                            width: "190%",
                                                            height: "45px",
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <div className="card__container__row3__r4" style={{marginTop: "10px"}}>
                                        <Button
                                            className={buySell === "buy" ? "buttonToggleButActive" : "buttonToggleBuy"}
                                            value="Buy"
                                            style={{
                                                lineHeight: "20px",
                                                width: mediaQueryVar ? "159px" : "130px",
                                                borderRadius: "27px",
                                                border: "1px solid #1877F2",
                                                padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                                                marginLeft: "-6px",
                                                backgroundColor: buySell === "buy" ? "" : "#1877F2",
                                                color: buySell === "buy" ? "" : "white",
                                                textTransform: "none",
                                                fontSize: "18px",
                                                fontWeight: "700",
                                            }}
                                            onClick={() => handleClick("buy")}
                                        >
                                            Buy
                                        </Button>
                                        <Button
                                            className={
                                                buySell === "sell" ? "buttonToggleSellActive" : "buttonToggleSell"
                                            }
                                            value="sell"
                                            style={{
                                                lineHeight: "20px",
                                                width: mediaQueryVar ? "159px" : "130px",
                                                borderRadius: "27px",
                                                border: "1px solid #1877F2",
                                                marginLeft: "10px",
                                                padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                                                backgroundColor: buySell === "sell" ? "" : "#1877F2",
                                                color: buySell === "sell" ? "" : "white",
                                                textTransform: "none",
                                                fontSize: "18px",
                                                fontWeight: "700",
                                            }}
                                            onClick={() => handleClick("sell")}
                                        >
                                            Sell
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    )}
                </div>
            </Modal>

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                    Create New Portfolio
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setIsDialogOpen(false);
                            setNewOptionValue("");
                        }}
                        sx={{position: "absolute", right: 13, top: 8}}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <TextField
                            autoFocus
                            label="Enter Name"
                            value={newOptionValue}
                            onChange={handleChange2}
                            fullWidth
                            margin="dense"
                        />
                        {showAlert && (
                            <Alert severity="error" sx={{marginTop: "8px"}}>
                                Hyphens are not allowed in the name.
                            </Alert>
                        )}
                    </div>
                </DialogContent>
                <DialogActions sx={{marginRight: "14px"}}>
                    <Button
                        onClick={() => {
                            setIsDialogOpen(false);
                            setNewOptionValue("");
                        }}
                        sx={{fontWeight: "bold"}}
                        variant="outlined"
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDialogSubmit}
                        variant="contained"
                        color="primary"
                        sx={{fontWeight: "bold"}}
                        disabled={!newOptionValue.trim()}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbaropen}
                autoHideDuration={5000}
                onClose={closesnackbar}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            >
                <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
            </Snackbar>

            <Grid item xs={12} align="left">
                
                {mediaQueryVar ? null : (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between", // Changed from space-between to flex-end
                            marginTop: "0px",
                            marginLeft: "5px",
                            fontSize: "20px",
                        }}
                    >
                        <Typography style={{fontSize: "20px", fontWeight: "700"}}>  </Typography>
                       
                    </div>
                )}
            </Grid>
            
           
            {mediaQueryVar ? null : (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative", width: "100%" }}>
                <Autocomplete
  freeSolo
  disableClearable
  options={userData ? cryptos.map((crypto) => crypto.symbol) : []}
  value={selectedCrypto}
  onChange={(event, newValue, reason) => {
    if (!userData) return;

    if (reason === "selectOption" && newValue) {
      const selected = cryptos.find(
        (crypto) => crypto.symbol.toLowerCase() === newValue.toLowerCase()
      );
      if (selected) {
        setSelectedCrypto(selected.symbol);
        setSelectedCryptoName(selected.slug);
        setLoader(false);
        setSortedSlugData([]);
      }
    }
  }}
  onFocus={(e) => {
    const user = localStorage.getItem("cw_portfolio_user");
    if (!user) {
      e.target.blur(); // prevent input focus
      handleLoginClick(); // show login popup
    }
  }}
  onMouseDown={(e) => {
    const user = localStorage.getItem("cw_portfolio_user");
    if (!user) {
      e.preventDefault(); // block dropdown opening
      handleLoginClick(); // show login popup
    }
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      variant="outlined"
      placeholder="Choose Crypto"
      inputProps={{
        ...params.inputProps,
        readOnly: !userData, // prevent typing if not logged in
      }}
      sx={{
        marginTop: "20%",
        marginBottom: "20%",
        width: "320px",
        height: "46px",
        fontWeight: "bold",
        "& .MuiOutlinedInput-root": {
          display: "flex",
          alignItems: "center",
          borderRadius: "48px",
          height: "46px",
          background:
            "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
          paddingLeft: "15px",
          paddingRight: "15px",
          border: "1px solid var(--Main-Primary, #1877F2)",
          "& input": {
            color: "black",
            fontWeight: "bold",
            textAlign: "left",
            fontSize: "18px",
            padding: "10px 0",
            "&::placeholder": {
              color: "black",
              opacity: 1,
            },
          },
          "& fieldset": {
            border: "none",
          },
          "& .MuiAutocomplete-endAdornment": {
            display: "none",
          },
        },
      }}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <img
            src={window.constants.asset_path + "/images/dropdown.png"}
            alt="open_in"
            width={40}
            height={40}
            style={{ marginLeft: "0px" }}
          />
        ),
      }}
    />
  )}
/>


</Box>
            )}
            

            {mediaQueryVar ? null : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                        marginLeft: "0px",
                    }}
                >
                    <button
                        style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                            borderRadius: "6px",
                            background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                            padding: "0px 4px",
                            marginBottom: "4px",
                            color: "black",
                        }}
                    >
                        Performance Metrics
                    </button>
                </div>
            )}

            {mediaQueryVar ? null : !loader ? (
                <div
                    style={{
                        backgroundColor: "#e7f2fd",
                        borderRadius: "10px",
                        padding: "16px",
                        width: "100%",
                        fontFamily: "Satoshi, sans-serif",
                    }}
                >
                    {Array(6)
                    .fill("")
                    .map((_, index) => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "8px",
                                marginTop: "10px",
                            }}
                            key={index}
                        >
                            <div
                                style={{
                                    height: "16px",
                                    backgroundColor: "#ccc",
                                    borderRadius: "4px",
                                    width: "40%",
                                    animation: "shimmer 1.2s infinite linear",
                                    background: "linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%)",
                                    backgroundSize: "800px 104px",
                                }}
                            />
                            <div
                                style={{
                                    height: "16px",
                                    backgroundColor: "#ccc",
                                    borderRadius: "4px",
                                    width: "60%",
                                    animation: "shimmer 1.2s infinite linear",
                                    background: "linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%)",
                                    backgroundSize: "800px 104px",
                                }}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div
                    style={{
                        backgroundColor: "#E5F3FF",
                        borderRadius: "8px",
                        border: "0.3px solid #1877F2",
                        padding: "12px",
                        width: "100%",
                        fontFamily: "Satoshi, sans-serif",
                        marginTop: "10px",
                        fontWeight: "400",
                    }}
                >
                    <div
  style={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
  }}
>
  {/* Left-aligned content */}
  <div style={{ display: "flex", alignItems: "center" }}>
    <span style={{ fontWeight: "500" }}>Crypto</span> {/* "Crypto" text */}
    <span style={{ marginLeft: "6px" }}>{slug}</span> {/* Crypto Slug */}
    <img
      alt="logo"
      src={symbolLogo}
      width={30}
      height={30}
      style={{
        borderRadius: "19px",
        border: "2px solid #000",
        width: "30px",
        height: "30px",
        marginLeft: "8px",
      }}
    />
  </div>

  {/* Right-aligned content */}
  <div style={{ display: "flex", alignItems: "center" }}>
    <span style={{ fontWeight: "500", marginRight: "10px" }}>${currentPrice}</span>
    <span
      style={{
        color: parseFloat(tfhrs) > 0 ? "green" : "red",
        fontWeight: "500",
      }}
    >
      {parseFloat(tfhrs) > 0 ? `+${tfhrs}` : tfhrs}
    </span>
  </div>
</div>


                    <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                        <div>7 Days</div>
                        <div style={{color: "green"}}>
                            <span
                                style={{
                                    color: parseFloat(sevenDays) > 0 ? "green" : "red",
                                }}
                            >
                                {parseFloat(sevenDays) > 0 ? `+${sevenDays}` : sevenDays}
                            </span>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                        <div>30 Days</div>
                        <div style={{color: "green"}}>
                            <span
                                style={{
                                    color: parseFloat(thirtyDays) > 0 ? "green" : "red",
                                }}
                            >
                                {parseFloat(thirtyDays) > 0 ? `+${thirtyDays}` : thirtyDays}
                            </span>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                        <div>12 Months</div>
                        <div style={{color: "green"}}>
                            <span
                                style={{
                                    color: yearDays == "NA" ? "black" : parseFloat(yearDays) > 0 ? "green" : "red",
                                }}
                            >
                                {parseFloat(yearDays) > 0 ? `+${yearDays}` : yearDays}
                            </span>
                        </div>
                    </div>
                    
                    <div style={{display: "flex", justifyContent: "space-between", marginBottom: "3px"}}>
                        <div>Market Cap</div>
                        <div> {marketCap}</div>
                    </div>
                    
                </div>
            )}
            



          
                
     
            {mediaQueryVar
                ? null
                : loader === true && sortedSlugdata.length>0 && ( <div
                style={{
                  backgroundColor: "#1877F2", // Blue color
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "400",
                  padding: "10px 12px",
                  width: "100%",
                  marginLeft:"0px",
                  height:"50px",
                  color:"#fff",
                  marginBottom:"1.5%",
                  marginTop:"40px"
                }}
              >
                Best Cryptos For Replacement
              </div>
            )}
            {mediaQueryVar
                ? null
                : loader === true && sortedSlugdata.length>0 ? ( <div
                    style={{
                       
                      paddingBottom:"0%",
          overflowX: "hidden",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          WebkitOverflowScrolling: "touch",
          // Hides scrollbar for Firefox
          scrollbarWidth: "none",
                    }}
                  >
                   
        
                    <Grid container spacing={0} sx={{marginTop:"20px"}}>
                      {sortedSlugdata.map((obj, idx) => (
                        <Grid item xs={12} md={6} key={idx}>
                          <div
                            key={idx}
                            style={{
                             width:"95%",
                              marginLeft: "4px", 
                              borderRadius : "10px",
                              backgroundColor: "white",
                              border:" 1px #E2E2E4 solid",
                              padding : "15px",
                              marginBottom: "25px",
                              marginRight: "-15px"
                      }}
                          >
                            {/* First column data */}
                            <div>
                            <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px #E2E2E4 solid",
            marginBottom: "10px",
            fontSize: mediaQueryVar === false ? "13px" : "",
          }}
        >
          {/* Left Section - Logo & Name */}
          <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
            <img
              className="b57ac673f06a4b0338a596817eb0a5Icon"
              alt="logo"
              src={obj.logo}
              style={{
                width: "38px",
                height: "38px",
                border: "2px solid black",
                borderRadius: "20px",
                marginRight: "8px",
                marginBottom:"10px"
              }}
            />
        
            {/* Name or Link */}
            <div>
              {obj["article_link"] ? (
                <a href={obj.article_link} style={{ color: "black",textDecoration: "underline" }}>
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      fontFamily: "Satoshi, sans-serif",
                      color: "black",
                      marginBottom:"10px"
                    }}
                  >
                    {obj.slug}
                  </div>
                </a>
              ) : (
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "Satoshi, sans-serif",
                    color: "black",
                    marginBottom:"10px",
                    textDecoration: "underline"
                  }}
                >
                  {obj.slug}
                </div>
              )}
            </div>
          </div>
        
          {/* Center Section - Recommendation (Aligned in Middle) */}
          <div
            style={{
              flex: 1,
              textAlign: "center",
              fontWeight: "500",
              fontSize: '14px',
              marginBottom:"10px",
              color:
                obj.recommend === "Hold"
                  ? "orange"
                  : obj.recommend === "Buy"
                  ? "green"
                  : obj.recommend === "Sell"
                  ? "red"
                  : "black",
            }}
          >
            {obj.recommend}
          </div>
        
          {/* Right Section - Button */}
          <Button
                                                                      className="btn"
                                                                      variant="outlined"
                                                                      onClick={() => handleAddToPortfolio(obj.slug)}
                                                                      style={{
                                                                          backgroundColor: "white",
                                                                          padding: "4px 10px",
                                                                          fontFamily: "Satoshi, sans-serif",
                                                                          borderRadius: "48px",
                                                                          border: "1px solid #1877F2",
                                                                          textTransform: "none",
                                                                          fontSize: mediaQueryVar === false ? "10px" : "11px",
                                                                          fontWeight: "500",
                                                                          color: "#1877F2",
                                                                          whiteSpace: "nowrap",
                                                                          width: "120px",
                                                                          height: "30px",
                                                                          marginLeft: "20px",
                                                                          marginBottom: "10px",
                                                                      }}
                                                                  >
                                                                      Add to Portfolio
                                                                  </Button>
          
        </div>
        
                              
                                <div style={{ width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center" }}>
                                  <span
                                    style={{
                                      color: '#7F7F80',
                                      fontSize: '14px',
                                      letterSpacing: '0.46px',
                                      fontFamily: "Satoshi, sans-serif",
                                      fontWeight: 500,
                                      marginBottom: "5px"
                                    }}
                                  >
                                    Price
                                  </span>
                                  <span
                                     style={{
                                      color: 'black',
                                      fontSize: '14px',
                                      fontFamily: "Satoshi, sans-serif",
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      marginBottom: "5px"
        ,
                                      whiteSpace: 'nowrap', // Ensures the text does not wrap
                                      maxWidth: '60%' // Adjust this percentage as needed to fit within the container
                                    }}
                                  >
                                    {obj.price_today}
                                  </span>
                                  
        
                                
                                </div>
                                
                                <div style={{ width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"}}>
                                  <span
                                   style={{
                                    color: '#7F7F80',
                                    fontSize: '14px',
                                    letterSpacing: '0.46px',
                                    fontFamily: "Satoshi, sans-serif",
                                    fontWeight: 500,
                                    marginBottom: "5px"
        
                                  }}
                                  >
                                    30 Days
                                  </span>
                                  <span
                                     style={{
                                      color: 'black',
                                      fontSize: '14px',
                                      fontFamily: "Satoshi, sans-serif",
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      marginBottom: "5px",
        
                                      whiteSpace: 'nowrap', // Ensures the text does not wrap
                                      maxWidth: '60%' // Adjust this percentage as needed to fit within the container
                                    }}
                                  >
                                    {obj.thirtyd_change}
                                  </span>
                                </div>
        
        
                                <div style={{ width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"}}>
                                  <span
                                   style={{
                                    color: '#7F7F80',
                                    fontSize: '14px',
                                    letterSpacing: '0.46px',
                                    fontFamily: "Satoshi, sans-serif",
                                    fontWeight: 500,
                                    marginBottom: "5px"
        
                                  }}
                                  >
                                  Market Cap
                                  </span>
                                  <span
                                     style={{
                                      color: 'black',
                                      fontSize: '14px',
                                      fontFamily: "Satoshi, sans-serif",
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      marginBottom: "5px",
        
                                      whiteSpace: 'nowrap', // Ensures the text does not wrap
                                      maxWidth: '60%' // Adjust this percentage as needed to fit within the container
                                    }}
                                  >
                                    {obj.buy_percent}
                                  </span>
                                </div>
                                
                               
                              
        
                             
                                
                                 
                                
                                <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center" // This ensures vertical alignment is centered
          }}
        >
          <span
            style={{
              color: '#7F7F80',
              fontSize: '14px',
              letterSpacing: '0.46px',
              fontFamily: "Satoshi, sans-serif",
              fontWeight: 500
            }}
          >
            Forecast 2025
          </span>
          <span
            style={{
              color: 'black',
              fontSize: '14px',
              fontFamily: "Satoshi, sans-serif",
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap', // Ensures the text does not wrap
              maxWidth: '60%' // Adjust this percentage as needed to fit within the container
            }}
          >
            {obj.price_prediction_2025}
          </span>
        </div>
        
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop:"5px",
            alignItems: "center" // This ensures vertical alignment is centered
          }}
        >
          <span
            style={{
              color: '#7F7F80',
              fontSize: '14px',
              letterSpacing: '0.46px',
              fontFamily: "Satoshi, sans-serif",
              fontWeight: 500
            }}
          >
           Top 100 Holders
          </span>
          <span
            style={{
              color: 'black',
              fontSize: '14px',
              fontFamily: "Satoshi, sans-serif",
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap', // Ensures the text does not wrap
              maxWidth: '60%' // Adjust this percentage as needed to fit within the container
            }}
          >
            {obj.holdings || 'NA'}
          </span>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center" // This ensures vertical alignment is centered
          }}
        >
          <span
            style={{
              color: '#7F7F80',
              fontSize: '14px',
              letterSpacing: '0.46px',
              fontFamily: "Satoshi, sans-serif",
              fontWeight: 500
            }}
          >
            Grade
          </span>
          <span
            style={{
              
              color: 'black',
              fontSize: '14px',
              fontFamily: "Satoshi, sans-serif",
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap', // Ensures the text does not wrap
              maxWidth: '60%', // Adjust this percentage as needed to fit within the container,
              marginTop:"5px",
              marginBottom:"5px"
            }}
          >
            {obj.grade === 'Outperformer' ? (
            <button disabled style={{fontWeight: "bold", padding: "3px" , borderRadius: "5px",backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "100%",fontSize: "14px" }}>Outperformer</button>
        ) : obj.grade === 'Emerging Outperformer' ? (
          <button disabled style={{ fontWeight: "bold", padding: "3px" , borderRadius: "5px",backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "100%",fontSize:  "14px"}}>Outperformer</button>
        ) : 
        
        
        obj.grade === 'Moderate Performer' ? (
            <button disabled style={{ marginRight:"2px",alignItems: 'center', fontWeight: "bold", padding: "3px", borderRadius: "5px", backgroundColor: '#F9F4E7', color: '#D4AF37', border: '2px solid #D4AF37', width: "100%" }}>Moderate Performer</button>
        ) : obj.grade === 'Underperformer' ? (
            <button disabled style={{ marginRight:"2px",alignItems: 'center', fontWeight: "bold", padding: "3px", borderRadius: "5px", backgroundColor: '#FFE3E3', color: '#FF4141', border: '2px solid #FF4141', width: "100%" }}>Underperformer</button>
        ) : null}
        
          </span>
        </div>
        
                                <div style={{ width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"}}>
                                  <span
                                   style={{
                                    color: '#7F7F80',
                                    fontSize: '14px',
                                    letterSpacing: '0.46px',
                                    fontFamily: "Satoshi, sans-serif",
                                    fontWeight: 500,
                                    marginBottom: "3px"
        
                                  }}
                                  >
                                    Risk
                                  </span>
                                  <span
                                     style={{
                                      color: obj.risk === 'High' ? 'red' : 
                   obj.risk === 'Medium' ? 'orange' : 
                   obj.risk === 'Low' ? 'green' : 
                   obj.risk === 'N/A' ? 'black' : 'black', 
                                      fontSize: '14px',
                                      fontFamily: "Satoshi, sans-serif",
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      marginBottom: "5px",
        
                                      whiteSpace: 'nowrap', // Ensures the text does not wrap
                                      maxWidth: '60%' // Adjust this percentage as needed to fit within the container
                                    }}
                                  >
                                    {obj.risk}
                                  </span>
                                </div>  
                                <div style={{ width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center", marginTop:"5px"}}>
                                  <span
                                   style={{
                                    color: '#7F7F80',
                                    fontSize: '14px',
                                    letterSpacing: '0.46px',
                                    fontFamily: "Satoshi, sans-serif",
                                    fontWeight: 500,
                                    marginBottom: "5px",
                                    
        
                                  }}
                                  >
                                    Category
                                  </span>
                                  <span
                                     style={{
                                      color: 'black',
                                      fontSize: '14px',
                                      fontFamily: "Satoshi, sans-serif",
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      marginBottom: "5px",
        
                                      whiteSpace: 'nowrap', // Ensures the text does not wrap
                                      maxWidth: '60%' // Adjust this percentage as needed to fit within the container
                                    }}
                                  >
                                    {obj.category}
                                  </span>
                                </div> 
                            </div>
                          </div>
              
                        </Grid>
                      ))}
                      
            </Grid>
            </div>
            ):(
                sortedSlugdata.length === 0 && loader === true && fileReplacement===true &&(
                    <div
                    style={{
                        border: "1px solid #B6B6B8",
                        margin: "0px 0.5%",
                        textAlign: "center",
                        color: "black",
                        fontSize: "18px",
                        backgroundColor: "white",
                        padding: "2%",
                        borderRadius: "8px",
                        marginBottom: "2%",
                        marginTop:"50px"
                    }}
                >
                    {slug} is an Outperformer and there are no recommendations at the moment.
                </div>
                  )
              )}
            

            
            
            {mediaQueryVar
                ? null
                : loader === true && (
                    <button
  onClick={() => {
    navigate(`/crypto-screener`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }}
  style={{
    padding: "14px 20px",
    fontSize: "22px",
    background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
    color: "#1E88E5",
    border: "2px solid #1E88E5",
    borderRadius: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center", // Centers content
    gap: "10px",
    cursor: "pointer",
    fontWeight: "700",
    marginBottom: "20%",
    width: "92%",
    height: "36px",
    marginTop:sortedSlugdata.length>0?"10%":"20%",
    marginLeft:"4%",
  }}
>
  <span
    style={{
      position: "relative",
      display: "flex",
      alignItems: "center",
    }}
  >
    <span
      style={{
        width: "21px",
        height: "20px",
        backgroundImage: `url(${window.constants.asset_path}/images/down-graph.svg)`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    ></span>
    <span
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "23px",
        height: "23px",
        backgroundImage: `url(${window.constants.asset_path}/images/lens-new.svg)`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        marginLeft: "2px",
        marginBottom: "3px",
      }}
    ></span>
  </span>

  <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    Crypto Screener
    <img
      src={window.constants.asset_path + "/images/link.svg"}
      width="22"
      height="22"
      alt="link"
    />
  </span>
</button>

                )}
                
  
            

            {/* {mediaQueryVar
                ? null
                : loader === true && (
                      <div>
                          <p style={{fontSize: "20px", fontWeight: "700", color: "#1877F2", background: "white"}}>
                              Updates
                          </p>
                          <div
                              className="description"
                              style={{
                                  fontFamily: "Satoshi, sans-serif!important",
                                  borderRadius: "4px",
                                  padding: "2px",
                                  marginTop: "15px",
                                  marginBottom: "8%",
                              }}
                          >
                              {analysis_data.ai_news && analysis_data.ai_news != "NA" ? 
                              (
                                  analysis_data.ai_news
                                  .split("\n") 
                                  .filter((line) => line.trim() !== "") 
                                  .map((line, index) => (
                                      <p key={index}>{line.trim().replace(/^-\s, "")}</p> 
                                  ))
                              ) : (
                                  <p>No Updates Available</p>
                              )}
                          </div>
                      </div>
                  )} */}

            {mediaQueryVar ? (
                <div className="layout">
                    

                    <div
                        className="content"
                        style={{
                            margin: "0%",
                            padding: "10px",
                        }}
                    >
                        

                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative", width: "100%" }}>
                        <Autocomplete
  freeSolo
  disableClearable
  options={userData ? cryptos.map((crypto) => crypto.symbol) : []}
  value={selectedCrypto}
  onChange={(event, newValue, reason) => {
    if (!userData) return;

    if (reason === "selectOption" && newValue) {
      const selected = cryptos.find(
        (crypto) => crypto.symbol.toLowerCase() === newValue.toLowerCase()
      );
      if (selected) {
        setSelectedCrypto(selected.symbol);
        setSelectedCryptoName(selected.slug);
        setLoader(false);
        setSortedSlugData([]);
      }
    }
  }}
  onFocus={(e) => {
    const user = localStorage.getItem("cw_portfolio_user");
    if (!user) {
      e.target.blur(); // prevent input focus
      handleLoginClick(); // show login popup
    }
  }}
  onMouseDown={(e) => {
    const user = localStorage.getItem("cw_portfolio_user");
    if (!user) {
      e.preventDefault(); // block dropdown opening
      handleLoginClick(); // show login popup
    }
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      variant="outlined"
      placeholder="Choose Crypto"
      inputProps={{
        ...params.inputProps,
        readOnly: !userData, // prevent typing if not logged in
      }}
      sx={{
        marginTop: "20%",
        marginBottom: "20%",
        width: "320px",
        height: "46px",
        fontWeight: "bold",
        "& .MuiOutlinedInput-root": {
          display: "flex",
          alignItems: "center",
          borderRadius: "48px",
          height: "46px",
          background:
            "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
          paddingLeft: "15px",
          paddingRight: "15px",
          border: "1px solid var(--Main-Primary, #1877F2)",
          "& input": {
            color: "black",
            fontWeight: "bold",
            textAlign: "left",
            fontSize: "18px",
            padding: "10px 0",
            "&::placeholder": {
              color: "black",
              opacity: 1,
            },
          },
          "& fieldset": {
            border: "none",
          },
          "& .MuiAutocomplete-endAdornment": {
            display: "none",
          },
        },
      }}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <img
            src={window.constants.asset_path + "/images/dropdown.png"}
            alt="open_in"
            width={40}
            height={40}
            style={{ marginLeft: "0px" }}
          />
        ),
      }}
    />
  )}
/>


    </Box>
                        <div
                            style={{
                                borderRadius: "10px",
                                backgroundColor: "#fff",
                                padding: "10px 0px 0px 0px",
                                fontFamily: "Satoshi, sans-serif",
                                margin: "15px 5px",
                            }}
                        >
                            <button
                                style={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                    borderRadius: "12px",
                                    background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                    padding: "4px 10px",
                                    marginLeft: "5px",
                                    marginBottom: "8px",
                                    color: "black",
                                }}
                            >
                                Performance Metrics
                            </button>
                            {/* <button
      onClick={() => handleAddToPortfolio(slug)}
      style={{
        border: "1px solid #1877F2",
        borderRadius: "48px",
        padding: "6px 14px",
        backgroundColor: "white",
        color: "#1877F2",
        fontSize: "14px",
        height: "33px",
        marginLeft: "6px",
        fontWeight:"500"
      }}>
      Add to Watchlist
    </button> */}

                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <TableContainer
                                    style={{
                                        width: "100%",
                                        overflowX: "hidden",
                                        padding: 0,
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell
                                                    style={{
                                                        border: 0,
                                                        borderTop: "1px solid #1877F2",
                                                        borderBottom: "1px solid #1877F2",
                                                        borderRight: "1px solid #1877F2",
                                                        padding: 0,
                                                        width: "30%",
                                                    }}
                                                >
                                                    <button
                                                        disabled
                                                        style={{
                                                            textAlign: "center",
                                                            color: "#1877F2",
                                                            fontSize: "16px",
                                                            width: "100%",
                                                            height: "100%",
                                                            background: "rgba(24, 119, 242, 0.12)",
                                                            padding: "19px 12px 5px 12px",
                                                            fontWeight: "900",
                                                            border: "none",
                                                        }}
                                                    >
                                                        Crypto
                                                    </button>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    style={{
                                                        border: 0,
                                                        borderTop: "1px solid #1877F2",
                                                        borderBottom: "1px solid #1877F2",
                                                        borderRight: "1px solid #1877F2",
                                                        padding: 0,
                                                        width: "12%",
                                                    }}
                                                >
                                                    <button
                                                        disabled
                                                        style={{
                                                            textAlign: "center",
                                                            color: "#1877F2",
                                                            fontSize: "16px",
                                                            width: "100%",
                                                            height: "100%",
                                                            background: "rgba(24, 119, 242, 0.12)",
                                                            padding: "19px 12px 5px 12px",
                                                            fontWeight: "900",
                                                            border: "none",
                                                        }}
                                                    >
                                                        7 Days
                                                    </button>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    style={{
                                                        border: 0,
                                                        borderTop: "1px solid #1877F2",
                                                        borderBottom: "1px solid #1877F2",
                                                        borderRight: "1px solid #1877F2",
                                                        padding: 0,
                                                        width: "12%",
                                                    }}
                                                >
                                                    <button
                                                        disabled
                                                        style={{
                                                            textAlign: "center",
                                                            color: "#1877F2",
                                                            fontSize: "16px",
                                                            width: "100%",
                                                            height: "100%",
                                                            background: "rgba(24, 119, 242, 0.12)",
                                                            padding: "19px 12px 5px 12px",
                                                            fontWeight: "900",
                                                            border: "none",
                                                        }}
                                                    >
                                                        30 Days
                                                    </button>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    style={{
                                                        border: 0,
                                                        borderTop: "1px solid #1877F2",
                                                        borderBottom: "1px solid #1877F2",
                                                        borderRight: "1px solid #1877F2",
                                                        padding: 0,
                                                        width: "14%",
                                                    }}
                                                >
                                                    <button
                                                        disabled
                                                        style={{
                                                            textAlign: "center",
                                                            color: "#1877F2",
                                                            fontSize: "16px",
                                                            width: "100%",
                                                            height: "100%",
                                                            background: "rgba(24, 119, 242, 0.12)",
                                                            padding: "19px 12px 5px 12px",
                                                            fontWeight: "900",
                                                            border: "none",
                                                        }}
                                                    >
                                                        12 Months
                                                    </button>
                                                </StyledTableCell>

                                                

                                                <StyledTableCell
                                                    style={{
                                                        border: 0,
                                                        borderTop: "1px solid #1877F2",
                                                        borderBottom: "1px solid #1877F2",
                                                        borderRight: "1px solid #DADEDF",
                                                        padding: 0,
                                                        //width: "auto",
                                                    }}
                                                >
                                                    <button
                                                        disabled
                                                        style={{
                                                            textAlign: "center",
                                                            color: "#1877F2",
                                                            fontSize: "16px",
                                                            width: "100%",
                                                            height: "100%",
                                                            background: "rgba(24, 119, 242, 0.12)",
                                                            padding: "19px 12px 5px 12px",
                                                            fontWeight: "900",
                                                            border: "none",
                                                        }}
                                                    >
                                                        Market Cap
                                                    </button>
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {loader === false ? (
                                                <StyledTableRow>
                                                    {[...Array(5)].map((_, index) => (
                                                        <StyledTableCell key={index}>
                                                            <Stack>
                                                                <Skeleton
                                                                    sx={{marginTop: "10px"}}
                                                                    variant="rounded"
                                                                    height={40}
                                                                />
                                                            </Stack>
                                                        </StyledTableCell>
                                                    ))}
                                                </StyledTableRow>
                                            ) : (
                                                <StyledTableRow style={{backgroundColor: "white"}}>
                                                    <StyledTableCell
                                                        component="th"
                                                        scope="row"
                                                        style={{
                                                            borderBottom: "none",
                                                            color: "#000",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            paddingLeft: "16px",
                                                            paddingTop: "8px",
                                                            paddingBottom: "8px",
                                                            borderRight: "1px solid #1877F2",
                                                            width: "auto",
                                                            height: "5vw",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            {/* Left section with logo and name */}
                                                            <div style={{display: "flex", alignItems: "center"}}>
                                                                <img
                                                                    width={35}
                                                                    height={35}
                                                                    style={{
                                                                        borderRadius: "19px",
                                                                        border: "2px solid black",
                                                                        marginLeft: "-8px",
                                                                    }}
                                                                    alt="symbolLogo"
                                                                    src={symbolLogo}
                                                                />
                                                                <span style={{marginLeft: "8px"}}>{slug}</span>
                                                            </div>

                                                            {/* Middle section with price */}
                                                            <div style={{textAlign: "center", fontWeight: "500"}}>
                                                                ${currentPrice}
                                                            </div>

                                                            {/* Right section with percentage */}
                                                            <div
                                                                style={{
                                                                    textAlign: "right",
                                                                    color: parseFloat(tfhrs) > 0 ? "green" : "red",
                                                                }}
                                                            >
                                                                {parseFloat(tfhrs) > 0 ? `+${tfhrs}` : tfhrs}
                                                            </div>
                                                        </div>
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        style={{
                                                            borderBottom: "none",
                                                            color: "#000",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                            paddingTop: "8px",
                                                            paddingBottom: "8px",
                                                            borderRight: "1px solid #1877F2",
                                                            width: "18%",
                                                            height: "5vw",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color: parseFloat(sevenDays) > 0 ? "green" : "red",
                                                            }}
                                                        >
                                                            {parseFloat(sevenDays) > 0 ? `+${sevenDays}` : sevenDays}
                                                        </span>
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        style={{
                                                            borderBottom: "none",
                                                            color: "#000",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                            paddingTop: "8px",
                                                            paddingBottom: "8px",
                                                            borderRight: "1px solid #1877F2",
                                                            width: "18%",
                                                            height: "5vw",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color: parseFloat(thirtyDays) > 0 ? "green" : "red",
                                                            }}
                                                        >
                                                            {parseFloat(thirtyDays) > 0 ? `+${thirtyDays}` : thirtyDays}
                                                        </span>
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        style={{
                                                            borderBottom: "none",
                                                            color: "#000",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                            paddingTop: "8px",
                                                            paddingBottom: "8px",
                                                            borderRight: "1px solid #1877F2",
                                                            width: "18%",
                                                            height: "5vw",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color:
                                                                    yearDays == "NA"
                                                                        ? "black"
                                                                        : parseFloat(yearDays) > 0
                                                                        ? "green"
                                                                        : "red",
                                                            }}
                                                        >
                                                            {parseFloat(yearDays) > 0 ? `+${yearDays}` : yearDays}
                                                        </span>
                                                    </StyledTableCell>

                                                   

                                                    <StyledTableCell
                                                        style={{
                                                            borderBottom: "none",
                                                            color: "#000",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                            paddingTop: "8px",
                                                            paddingBottom: "8px",
                                                            width: "18%",
                                                            height: "5vw",
                                                            borderRight: "1px solid #DADEDF",
                                                        }}
                                                    >
                                                        {marketCap}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                
                            </div>
                        </div>
                        


                        {loader === true && sortedSlugdata.length>0 && (
                        <div
      style={{
        backgroundColor: "#1877F2", // Blue color
        color: "white",
        fontSize: "22px",
        fontWeight: "400",
        padding: "10px 12px",
        width: "99%",
        marginLeft:"5px",
        height:"50.461px",
        color:"#fff",
        marginBottom:"1.5%",
        marginTop:"7%"
      }}
    >
      Best Cryptos For Replacement
    </div>
    )}

{loader === true && sortedSlugdata.length>0 ? (
<Grid container spacing={0}>
            {sortedSlugdata.map((obj, idx) => (
                          <Grid item xs={12} md={6} key={idx}>
                            <div
                              key={idx}
                              style={{
                               
                                marginLeft: "5px", 
                                borderRadius : "10px",
                                backgroundColor: "white",
                                border:" 1px #ECECEC solid",
                                padding : "24px",
                                marginBottom: "20px",
                                marginRight: "10px"
                        }}
                            >
                              {/* First column data */}
                              <div>
                              <div
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px #E2E2E4 solid",
                marginBottom: "8px",
                fontSize: mediaQueryVar === false ? "13px" : "",
              }}
            >
              {/* Logo & Name */}
              <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
                <img
                  className="b57ac673f06a4b0338a596817eb0a5Icon"
                  alt="logo"
                  src={obj.logo}
                  style={{
                    width: "38px",
                    height: "38px",
                    border: "2px solid black",
                    borderRadius: "20px",
                    marginRight: "8px",
                    marginBottom:"10px"
                  }}
                />
            
                {/* Name or Link */}
                <div>
                  {obj["article_link"] ? (
                    <a href={obj.article_link} style={{ color: "black", textDecoration: "underline" }}>
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          fontFamily: "Satoshi, sans-serif",
                          color: "black",
                          marginBottom:"10px"
                        }}
                      >
                        {obj.slug}
                      </div>
                    </a>
                  ) : (
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        fontFamily: "Satoshi, sans-serif",
                        color: "black",
                        marginBottom:"10px",
                        textDecoration: "underline"
                      }}
                    >
                      {obj.slug}
                    </div>
                  )}
                </div>
              </div>
            
              {/* Recommendation Status (Centered) */}
              <div
                style={{
                  flex: 1,
                  textAlign: "center", // Centers text horizontally
                  fontWeight: "500",
                  marginBottom:"8px",
                  marginRight:"22px",
                  color:
                    obj.recommend === "Hold"
                      ? "orange"
                      : obj.recommend === "Buy"
                      ? "green"
                      : obj.recommend === "Sell"
                      ? "red"
                      : "black",
                }}
              >
                {obj.recommend}
              </div>
            
              {/* Button (Right Side) */}
              <Button
                                                                          className="btn"
                                                                          variant="outlined"
                                                                          onClick={() => handleAddToPortfolio(obj.slug)}
                                                                          style={{
                                                                              backgroundColor: "white",
                                                                              padding: "4px 10px",
                                                                              fontFamily: "Satoshi, sans-serif",
                                                                              borderRadius: "48px",
                                                                              border: "1px solid #1877F2",
                                                                              textTransform: "none",
                                                                              fontSize: mediaQueryVar === false ? "10px" : "11px",
                                                                              fontWeight: "500",
                                                                              color: "#1877F2",
                                                                              whiteSpace: "nowrap",
                                                                              width: "120px",
                                                                              height: "30px",
                                                                              marginLeft: "20px",
                                                                              marginBottom: "10px",
                                                                          }}
                                                                      >
                                                                          Add to Portfolio
                                                                      </Button>
              
            </div>
            
                                
                                
                                
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div style={{ flex: "0 0 auto", width: "17%" }}>
                                    <span
                                      style={{
                                        color: '#7F7F80', 
                                        fontSize: '14px', 
                                        letterSpacing: '0.46px',
                                        fontFamily: "Satoshi, sans-serif", 
                                        fontWeight: 500
                                      }}
                                    >
                                      Price
                                    </span>
                                  </div>
                                  <div style={{ flex: "0 0 auto", width: "35%", // Add border to separate sections
                    paddingRight: '0px',marginRight:"5px" }}>
                                    <span
                                      style={{
                                        color: 'black', 
                                        fontSize: '14px', 
                                        overflow: 'hidden',
                                        fontFamily: "Satoshi, sans-serif", 
                                        textOverflow: 'ellipsis',
                                        fontWeight:'500',
                                        
                                      }}
                                    >
                                      {obj.price_today} 
                                    </span>
                                  </div>
            
                                  <div style={{ width: '2px', backgroundColor: '#E2E2E4', height: '42px',
                                    marginTop:"10px",
                                    marginLeft:"-30px",
                                    marginRight:"25px",
                                    marginBottom:"-30px"
                                   }}></div>
                                  
                                  <div style={{ flex: "0 0 auto", width: "23%" }}>
                                    <span
                                      style={{
                                        color: '#7F7F80', 
                                        fontSize: '14px', 
                                        letterSpacing: '0.46px',
                                        fontFamily: "Satoshi, sans-serif", 
                                        fontWeight: 500
                                      }}
                                    >
                                      Market Cap
                                    </span>
                                  </div>
                                  <div style={{ flex: "0 0 auto", marginLeft: "auto" }}>
                                    <span
                                      style={{
                                        color: 'black', 
                                        fontSize: '14px',
                                        fontFamily: "Satoshi, sans-serif", 
                                        textOverflow: 'ellipsis', 
                                        overflow: 'hidden'
                                      }}
                                    >
                                      {obj.buy_percent}
                                    </span>
                                  </div>
                                </div>
            
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div style={{ flex: "0 0 auto", width: "17%" }}>
                                    <span
                                      style={{
                                        color: '#7F7F80', 
                                        fontSize: '14px', 
                                        letterSpacing: '0.46px',
                                        fontFamily: "Satoshi, sans-serif", 
                                        fontWeight: 500
                                      }}
                                    >
                                      30 Days
                                    </span>
                                  </div>
                                  <div style={{ flex: "0 0 auto", width: "35%" }}>
                                    <span
                                      style={{
                                         overflow: 'hidden', 
                                         textOverflow: 'ellipsis',
                                         fontFamily: "Satoshi, sans-serif", 
                                         fontSize: '14px', 
                                         color: 'black'
                                      }}
                                    >
                                      {obj.thirtyd_change}
                                    </span>
                                  </div>
                                  <div style={{ flex: "0 0 auto", width: "23%" }}>
                                    <span
                                      style={{
                                        color: '#7F7F80', 
                                        fontSize: '14px', 
                                        letterSpacing: '0.46px',
                                        fontFamily: "Satoshi, sans-serif", 
                                        fontWeight: 500
                                      }}
                                    >
                                      Forecast 2025
                                    </span>
                                  </div>
                                  <div style={{ flex: "0 0 auto", marginLeft: "auto" }}>
                                    <span
                                      style={{
                                        color: 'black', 
                                        fontSize: '14px',
                                        fontFamily: "Satoshi, sans-serif", 
                                        textOverflow: 'ellipsis', 
                                        overflow: 'hidden'
                                      }}
                                    >
                                      {obj.price_prediction_2025}
                                    </span>
                                  </div>
                                </div>
                                
                                
            
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div style={{ flex: "0 0 auto", width: "17%" }}>
                                    <span
                                      style={{
                                        color: '#7F7F80', 
                                        fontSize: '14px', 
                                        letterSpacing: '0.46px',
                                        fontFamily: "Satoshi, sans-serif", 
                                        fontWeight: 500,
                                        whiteSpace : "nowrap"
                                      }}
                                    >
                                      Top 100 Holders
                                    </span>
                                  </div>
                                  <div style={{ flex: "0 0 auto", width: "35%" }}>
                                    <span
                                      style={{
                                         overflow: 'hidden', 
                                         textOverflow: 'ellipsis',
                                         fontFamily: "Satoshi, sans-serif", 
                                         fontSize: '14px', 
                                         color: 'black',
                                         marginLeft:"50px"
                                      }}
                                    >
                                      {obj.holdings || 'NA'}
                                    </span>
                                  </div>
                                  <div style={{ flex: "0 0 auto", width: "23%" }}>
                                    <span
                                      style={{
                                        color: '#7F7F80', 
                                        fontSize: '14px', 
                                        letterSpacing: '0.46px',
                                        fontFamily: "Satoshi, sans-serif", 
                                        fontWeight: 500
                                      }}
                                    >
                                      Grade
                                    </span>
                                  </div>
                                  <div style={{ flex: "0 0 auto", marginLeft: "auto" }}>
                                    <span
                                      style={{
                                        color: 'black', 
                                        fontSize: '14px',
                                        fontFamily: "Satoshi, sans-serif", 
                                        textOverflow: 'ellipsis', 
                                        overflow: 'hidden'
                                      }}
                                    >
                                       {
              obj.grade === 'Outperformer' ? (
              <button disabled style={{ fontWeight: "bold", padding: "3px" , borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "100%",fontSize:  "12px" }}>Outperformer</button>
            ) : obj.grade === 'Emerging Outperformer' ? (
            <button disabled style={{ fontWeight: "bold", padding: "3px" , borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "100%",fontSize:  "12px"}}>Outperformer</button>
            ) :
            obj.grade === 'Moderate Performer' ? (
              <button disabled style={{ fontWeight: "bold", padding: "3px", borderRadius: "5px", backgroundColor: '#F9F4E7', color: '#D4AF37', border: '2px solid #D4AF37', width: "100%",fontSize: "12px"}}>Moderate Performer</button>
            )  : obj.grade === 'Underperformer' ? (
              <button disabled style={{ alignItems: 'center', fontWeight: "bold", padding:"3px ", borderRadius: "5px", backgroundColor: '#FFE3E3', color: '#FF4141', border: '2px solid #FF4141', width: "100%" }}>Underperformer</button>
            ) : null}
                                    </span>
                                  </div>
                                </div>
            
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div style={{ flex: "0 0 auto", width: "17%" }}>
                                    <span
                                      style={{
                                        color: '#7F7F80', 
                                        fontSize: '14px', 
                                        letterSpacing: '0.46px',
                                        fontFamily: "Satoshi, sans-serif", 
                                        fontWeight: 500
                                      }}
                                    >
                                      Category
                                    </span>
                                  </div>
                                  <div style={{ flex: "0 0 auto", width: "35%" }}>
                                    <span
                                      style={{
                                         overflow: 'hidden', 
                                         textOverflow: 'ellipsis',
                                         fontFamily: "Satoshi, sans-serif", 
                                         fontSize: '14px', 
                                         color: 'black'
                                      }}
                                    >
                                      {obj.category}
                                    </span>
                                  </div>
                                  <div style={{ flex: "0 0 auto", width: "23%" }}>
                                    <span
                                      style={{
                                        color: '#7F7F80', 
                                        fontSize: '14px', 
                                        letterSpacing: '0.46px',
                                        fontFamily: "Satoshi, sans-serif", 
                                        fontWeight: 500
                                      }}
                                    >
                                      Risk
                                    </span>
                                  </div>
                                  <div style={{ flex: "0 0 auto", marginLeft: "auto" }}>
                                  <span
              style={{
                color: obj.risk === 'High' ? 'red' : obj.risk === 'Medium' ? 'orange' : 'green',
                fontSize: '14px',
                fontFamily: 'Satoshi, sans-serif',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {obj.risk}
            </span>
            
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                      ):(
                        sortedSlugdata.length === 0 && loader === true && fileReplacement===true &&(
                            <div
                            style={{
                                border: "1px solid #B6B6B8",
                                margin: "0px 0.5%",
                                marginTop: "10px",
                                textAlign: "center",
                                color: "black",
                                fontSize: "18px",
                                backgroundColor: "white",
                                padding: "2%",
                                borderRadius: "8px",
                                marginBottom: "2%",
                                marginTop:"20px"
                            }}
                        >
                            {slug} is an Outperformer and there are no recommendations at the moment.
                        </div>
                          )
                      )}
        
        {evaluation_data && sortedSlugdata.length>0 && (
            <div
            style={{
              backgroundColor: "#1877F2", // Blue color
              color: "white",
              fontSize: "22px",
              fontWeight: "400",
              padding: "10px 12px",
              width: "99%",
              marginLeft:"5px",
              height:"25.230px",
              color:"#fff",
              marginBottom:"1.5%",
            }}
          >
            
          </div>

        )}
                        {analysis_data && (
                            <div
                                style={{
                                    borderRadius: "10px",
                                    backgroundColor: "#fff",
                                    padding: "10px 0px 0px 0px",
                                    fontFamily: "Satoshi, sans-serif",
                                    margin: "15px 5px",
                                }}
                            >
                                <button
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "700",
                                        border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        borderRadius: "12px",
                                        background:
                                            "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        padding: "4px 10px",
                                        marginLeft: "5px",
                                        marginBottom: "8px",
                                        color: "black",
                                    }}
                                >
                                    Influencer Signals
                                    {paid_member === "no" && (
                                        <img
                                            src={window.constants.asset_path + "/images/lock-new.svg"}
                                            width="24"
                                            height="24"
                                            style={{marginLeft: "4px"}}
                                            alt="lock"
                                        />
                                    )}
                                </button>

                                {paid_member === "yes" ? (
                                    <TableContainer
                                        style={{
                                            width: "100%",
                                            overflowX: "hidden",

                                            padding: 0,
                                        }}
                                    >
                                        <Table style={{borderCollapse: "collapse"}}>
                                            <TableHead>
                                                <TableRow>
                                                    {[
                                                        "All Influencers",
                                                        "Top 10% Influencers",
                                                        "Institutions",
                                                        "Whales",
                                                        "Individuals",
                                                        "Grade",
                                                    ].map((label, index, array) => (
                                                        <StyledTableCell
                                                            key={index}
                                                            className="cryptotableviewHeader"
                                                            style={{
                                                                padding: 0,
                                                                whiteSpace: "nowrap", // Prevent word wrapping
                                                                wordWrap: "normal", // Ensure words don't break
                                                                border: "none", // Remove all default borders
                                                                borderTop: "1px solid #1877F2", // Top border
                                                                borderBottom: "1px solid #1877F2", // Bottom border
                                                                borderRight:
                                                                    index !== array.length - 1
                                                                        ? "1px solid #DADEDF"
                                                                        : "none", // Right border for all but last column
                                                                height: "40px", // Increased height for the header
                                                                textAlign: "center", // Centering the text
                                                                paddingLeft: "12px", // Added padding for better readability,
                                                                background: "rgba(24, 119, 242, 0.12)",
                                                                paddingRight: "12px", // Added padding for better readability
                                                            }}
                                                        >
                                                            <button
                                                                disabled
                                                                style={{
                                                                    color: "#1877F2",
                                                                    fontSize: "16px",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    borderBottom: "none",
                                                                    background: "none",
                                                                    padding: "19px 12px 5px 12px",
                                                                    fontWeight: "900",
                                                                    border: "none", // Remove borders from the button
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                {label}
                                                            </button>
                                                        </StyledTableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {loader === false ? (
                                                    <StyledTableRow>
                                                        {[...Array(4)].map((_, index) => (
                                                            <StyledTableCell key={index}>
                                                                <Stack>
                                                                    <Skeleton
                                                                        sx={{marginTop: "8px"}}
                                                                        variant="rounded"
                                                                        height={40}
                                                                    />
                                                                </Stack>
                                                            </StyledTableCell>
                                                        ))}
                                                    </StyledTableRow>
                                                ) : (
                                                    <StyledTableRow style={{backgroundColor: "white"}}>
                                                        {[
                                                            analysis_data.hashtags,
                                                            analysis_data.top_10p_influencers,
                                                            analysis_data.indices,
                                                            analysis_data.whales,
                                                            analysis_data.social_media,
                                                            analysis_data.grade,
                                                        ].map((data, index, array) => (
                                                            <StyledTableCell
                                                                key={index}
                                                                style={{
                                                                    borderBottom: "none",
                                                                    color: "#000",
                                                                    fontSize: "16px",
                                                                    fontWeight: "500",
                                                                    textAlign: "center",
                                                                    paddingTop: "12px",
                                                                    paddingBottom: "12px",
                                                                    borderRight:
                                                                        index !== array.length - 1
                                                                            ? "1px solid #DADEDF"
                                                                            : "none",
                                                                    height: "5vw",
                                                                }}
                                                            >
                                                                {renderAction(data) || "-"}
                                                            </StyledTableCell>
                                                        ))}
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <div>
                                        <TableContainer
                                            style={{
                                                width: "100%",
                                                overflowX: "hidden",
                                                border: "1px solid #DADEDF",
                                                borderRadius: "0px",
                                                padding: 0,
                                            }}
                                        >
                                            <Table
                                                className="non_responsiveTable"
                                                aria-label="customized table"
                                                style={{borderCollapse: "collapse"}}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        {[
                                                            "All Influencers",
                                                            "Top 10% Influencers",
                                                            "Institutions",
                                                            "Whales",
                                                            "Individuals",
                                                            "Grade",
                                                        ].map((label, index, array) => (
                                                            <StyledTableCell
                                                                key={index}
                                                                className="cryptotableviewHeader"
                                                                style={{
                                                                    padding: 0,
                                                                    whiteSpace: "nowrap", // Prevent word wrapping
                                                                    wordWrap: "normal", // Ensure words don't break
                                                                    border: "none", // Remove all default borders
                                                                    borderTop: "1px solid #1877F2", // Top border
                                                                    borderBottom: "1px solid #1877F2", // Bottom border
                                                                    borderRight:
                                                                        index !== array.length - 1
                                                                            ? "1px solid #DADEDF"
                                                                            : "none", // Right border for all but last column
                                                                    height: "40px", // Increased height for the header
                                                                    textAlign: "center", // Centering the text
                                                                    paddingLeft: "12px", // Added padding for better readability,
                                                                    background: "rgba(24, 119, 242, 0.12)",
                                                                    paddingRight: "12px", // Added padding for better readability
                                                                }}
                                                            >
                                                                <button
                                                                    disabled
                                                                    style={{
                                                                        color: "#1877F2",
                                                                        fontSize: "16px",
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        borderBottom: "none",
                                                                        background: "none",
                                                                        padding: "19px 12px 5px 12px",
                                                                        fontWeight: "900",
                                                                        border: "none", // Remove borders from the button
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    {label}
                                                                </button>
                                                            </StyledTableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {loader === false ? (
                                                        <StyledTableRow>
                                                            {[...Array(7)].map((_, index) => (
                                                                <StyledTableCell key={index}>
                                                                    <Stack>
                                                                        <Skeleton
                                                                            sx={{marginTop: "8px"}}
                                                                            variant="rounded"
                                                                            height={40}
                                                                        />
                                                                    </Stack>
                                                                </StyledTableCell>
                                                            ))}
                                                        </StyledTableRow>
                                                    ) : (
                                                        <StyledTableRow style={{backgroundColor: "white"}}>
                                                            <td
                                                                colSpan="100%"
                                                                style={{textAlign: "center", height: "5vw"}}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        marginTop: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                >
                                                                    <button
                                                                        style={{
                                                                            background:
                                                                                "linear-gradient(90deg, #1877F2 -19.84%, #519DFF 99.98%)",
                                                                            color: "#fff",
                                                                            fontSize: "17px",
                                                                            fontWeight: "700",
                                                                            borderRadius: "25px",
                                                                            padding: "7px 20px 10px 20px",
                                                                            border: "none",
                                                                            cursor: "pointer",
                                                                            width: "auto",
                                                                            height: "40px",
                                                                        }}
                                                                        onClick={handleNavigation}
                                                                    >
                                                                        Premium Signals @ $1 per month
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </StyledTableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                )}
                            </div>
                        )}
                        {loader == true && (
                            <div style={{display: "flex", justifyContent: "center", gap: "5%"}}>
                                

                                <button
      onClick={() => {
        navigate(`/crypto-screener`);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
      style={{
        fontSize: "22px",
        background: "linear-gradient(90deg, #9AFFF9 20%, #FFF06D 100%)",
        color: "#1E88E5",
        border: "2px solid #1E88E5",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px",
        cursor: "pointer",
        fontWeight: "700",
        padding: "10px 20px",
        marginBottom: "2%",
        marginTop: "2%",
        width: "fit-content",
        minWidth: "41%",
        flexWrap: "nowrap",
      }}
    >
      <span
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            width: "21px",
            height: "20px",
            backgroundImage: `url(${window.constants.asset_path}/images/down-graph.svg)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></span>
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "25px",
            height: "25px",
            backgroundImage: `url(${window.constants.asset_path}/images/lens-new.svg)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginLeft: "4px",
            marginBottom: "7px",
          }}
        ></span>
      </span>
      <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        Crypto Screener
        <img
          src={`${window.constants.asset_path}/images/link.svg`}
          width="24"
          height="24"
          alt="link"
        />
      </span>
    </button>
                            </div>
                        )}
                        
                     
                       

                        
                    </div>
                </div>
            ) :  (
                null
            )}
           {mediaQueryVar && sortedData.length > 0 && (
  <div style={{ marginLeft: "20.5vw" }}>
    
    <Footer />
  </div>
)}

            
           
            {mediaQueryVar==false && sortedData.length > 0 && (
                <Footer/>
            )}
         
            {isLoginModalOpen && (
                <LoginPopUp
                    isLoginModalOpen={isLoginModalOpen}
                    setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
                    onClose={handleModalClose}
                />
            )}
            <Modal
                style={{
                    overlay: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1000,
                        marginLeft: mediaQueryVar === true ? "20%" : "",
                    },
                    content: {
                        align: "center",
                        width: "80%",
                        marginBottom: "20%",
                        height: "auto",
                        borderRadius: "8px",
                        position: "center",
                    },
                }}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Accuracy Details"
            >
                <img
                    className="frameIcon"
                    alt="frameIcon"
                    onClick={closeModal}
                    src="/images/frame17.svg"
                    style={{
                        background: "#F3F3F3",
                        width: "25px",
                        height: "25px",
                        marginTop: "1.5%",
                        marginBottom: "1%",
                    }}
                />
                <h4>Past Accuracy</h4>
                {modalData ? (
                    <table className="modal-table">
                        <thead>
                            <tr>
                                <th>Published On</th>
                                <th>Forecast Date</th>
                                <th style={{textAlign: "center"}}>Forecast Price</th>
                                <th style={{textAlign: "center"}}>Accuracy</th>
                            </tr>
                        </thead>
                        <tbody>
                            {modalData.map((detail, index) => (
                                <tr key={index}>
                                    <td>{detail.publish_date}</td>
                                    <td>{detail.target_date} 11:59 PM</td>
                                    <td style={{textAlign: "center"}}>{detail.forecast_price}</td>
                                    <td style={{textAlign: "center"}}>{detail.accuracy}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No data available</p>
                )}
            </Modal>
        </div>
    );
};

export default CryptoReplacement;
