import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  lazy,
  Suspense,
} from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Popover
} from "@mui/material";
import LoginPopUp from "./loginpopup";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import "./CryptoPage.css"; // Assuming you have a CSS file for styling
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import EmailPopUp from "./EmailPopUp.js";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { BottomNavigation, Button } from "@mui/material";
import SidebarMenuList from "../includes/sidebarMenuList.jsx";

import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  Paper,

} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Card, TextField } from "@mui/material";

import { DialogTitle, DialogContent, DialogActions } from "@mui/material"; // Import Material-UI components


import CloseIcon from "@mui/icons-material/Close";

import CreatableSelect from "react-select/creatable";
import {
  getTotalunit,
  getFetchdropdown,
} from "../apihelper/cryptocurrencylist";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const drawerWidth = 292;
const API_URL = "https://7866p03wcj.execute-api.us-east-1.amazonaws.com/dev/submitData";

const CustomPortfolio = (props) => {
  const { window1 } = props;
  const [marketCap, setMarketCap] = useState("Small Cap");
  const [riskLevel, setRiskLevel] = useState("Low");
  const [duration, setDuration] = useState("Short-term");
  const [returnLabel, setReturnLabel] = useState("7D Returns");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const loc = location.pathname;
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
   const mediaQueryVar = useMediaQuery(
        json2mq({
          minWidth: 900,
        }),
      );
      const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
      };
      const container =
      window1 !== undefined ? () => window1().document.body : undefined;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  const drawer = (
        <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
          <SidebarMenuList />
        </div>
      );
const [isLoginModalOpen, setLoginModalOpen] = useState(false);
      const handleLoginClick = () => {

        const originalUrl = "/custom-portfolio";
        sessionStorage.setItem("originalUrl", originalUrl);
        // Mask the URL by adding /login at the end without navigating
        window.history.pushState({}, "", "/login");
        localStorage.setItem("isPopupManuallyOpened", "true");
        // Open the login modal
        setLoginModalOpen(true);
      };
      const handleModalClose = () => {
        // Close the modal and reset the URL to the welcome page
        setLoginModalOpen(false);
        navigate(location.pathname, { replace: true });
      };

  const fetchRecommendations = async () => {
    setLoading(true);
    setError(null);

    try {
        console.log({
            market_cap_input: marketCap,
            risk: riskLevel,
            duration: duration,
        });

        const response = await axios.post(
            API_URL,
            JSON.stringify({
                market_cap: marketCap,
                risk: riskLevel,
                duration: duration,
            }),
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        let parsedData = response.data;

        if (typeof parsedData === "string") {
            try {
                parsedData = parsedData.replace(/\bNaN\b/g, "null");
                parsedData = JSON.parse(parsedData);
            } catch (error) {
                console.error("Error parsing JSON:", error, parsedData);
                setError("Invalid response format");
                return;
            }
        }

        if (parsedData && parsedData.recommended_cryptos) {
            setData(parsedData.recommended_cryptos); // ✅ Correcting the key
        } else {
            setData([]);
        }
    } catch (error) {
        console.error("API Request Failed:", error);
        setError("Failed to fetch data");
    } finally {
        setLoading(false);
    }
};

useEffect(() => {
  if (!loading) {
    setReturnLabel(
      duration === "Long-term"
        ? "1 Yr Returns"
        : duration === "Short-term"
        ? "7D Returns"
        : "30D Returns"
    );
  }
}, [loading]);


  const [openStates, setOpenStates] = useState({
    marketCap: false,
    riskLevel: false,
    duration: false,
  });

  const dropdowns = [
    { key: "marketCap", label: "Market Cap", value: marketCap, setValue: setMarketCap, options: ["Small Cap", "Mid Cap", "Large Cap"] },
    { key: "riskLevel", label: "Risk Level", value: riskLevel, setValue: setRiskLevel, options: ["Low", "Medium", "High"] },
    { key: "duration", label: "Investment Duration", value: duration, setValue: setDuration, options: ["Short-term", "Mid-term", "Long-term"] }
  ];

  return (
    <div className="crypto-page" style={{padding:mediaQueryVar? 0:"10px",margin: 0, backgroundColor: "#F2F2F2" }}>
      <Helmet>
        <title>
          Crypto Recommendations
        </title>
        
      </Helmet>

      

      
 {isLoginModalOpen && (
                        <LoginPopUp
                            isLoginModalOpen={isLoginModalOpen}
                            setLoginModalOpen={setLoginModalOpen}
                            onClose={handleModalClose}
                        />
                    )}
       
     


      {mediaQueryVar ? (
        <div className="layout">
          

          <div
            className="content"
            style={{
              margin: "0%",
              padding: "10px",
            }}
          >
            <Box sx={{ p: 4, maxWidth: "900px", margin: "auto", backgroundColor: "#f9f9f9", borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 3, textAlign: "center", color: "#1976d2" }}>
      AI-Powered Crypto Portfolios
      </Typography>

      {/* Dropdowns */}
      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 3 }}>
      {dropdowns.map(({ key, label, value, setValue, options }) => {
        const labelId = `${label.replace(/\s+/g, '-').toLowerCase()}-label`;

        return (
          <FormControl fullWidth key={key} variant="outlined">
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
              labelId={labelId}
              value={value}
              label={label}
              open={userData ? openStates[key] : false}
              onOpen={() => userData && setOpenStates((prev) => ({ ...prev, [key]: true }))}
              onClose={() => setOpenStates((prev) => ({ ...prev, [key]: false }))}
              onChange={(e) => setValue(e.target.value)}
              onMouseDown={(e) => {
                if (!localStorage.getItem('cw_portfolio_user')) {
                  e.preventDefault();
                  e.stopPropagation();
                  handleLoginClick();
                }
              }}
              onClick={(e) => {
                if (!localStorage.getItem('cw_portfolio_user')) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              {options.map((opt) => (
                <MenuItem key={opt} value={opt}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      })}
    </Box>

      {/* Fetch Button */}
      <Button variant="contained" fullWidth color="primary" onClick={fetchRecommendations} disabled={loading}>
        {loading ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Fetch Recommendations"}
      </Button>

      {/* Error Message */}
      {error && <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>{error}</Typography>}

      {/* Display recommendations */}
      {data && data.length > 0 && (
    <TableContainer component={Paper} sx={{ mt: 4, borderRadius: 2, boxShadow: 3 }}>
        <Table>
        <TableHead>
  <TableRow sx={{ backgroundColor: "#1976d2" }}>
    <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Symbol</TableCell>
    <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Name</TableCell>
    <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
    {returnLabel}
</TableCell>

    <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Market Cap</TableCell>
    <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Risk Level</TableCell>
  </TableRow>
</TableHead>
            <TableBody>
                {data.map((rec, index) => (
                    <TableRow key={index} sx={{ "&:nth-of-type(even)": { backgroundColor: "#f0f0f0" } }}>
                        <TableCell>{rec.symbol}</TableCell>
                        <TableCell>{rec.name}</TableCell>
                        <TableCell>{rec.selected_returns !== null ? `${(rec.selected_returns * 100).toFixed(2)}%` : "N/A"}</TableCell>
                        <TableCell>{rec.market_cap_category}</TableCell>
                        <TableCell>{rec.risk_level}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
)}

    </Box>

          </div>
        </div>
      
        
      ) : (
        <div style={{marginTop:"10px",}}>
         
         <Box sx={{ p: 3, maxWidth: "700px", margin: "auto", backgroundColor: "#f9f9f9", borderRadius: 2, boxShadow: 3,marginBottom:"4vh" }}>
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 3, textAlign: "center", color: "#1976d2" }}>
      AI-Powered Crypto Portfolios
      </Typography>

      {/* Dropdowns */}
      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 3 }}>
      {dropdowns.map(({ key, label, value, setValue, options }) => {
        const labelId = `${label.replace(/\s+/g, '-').toLowerCase()}-label`;

        return (
          <FormControl fullWidth key={key} variant="outlined">
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
              labelId={labelId}
              value={value}
              label={label}
              open={userData ? openStates[key] : false}
              onOpen={() => userData && setOpenStates((prev) => ({ ...prev, [key]: true }))}
              onClose={() => setOpenStates((prev) => ({ ...prev, [key]: false }))}
              onChange={(e) => setValue(e.target.value)}
              onMouseDown={(e) => {
                if (!localStorage.getItem('cw_portfolio_user')) {
                  e.preventDefault();
                  e.stopPropagation();
                  handleLoginClick();
                }
              }}
              onClick={(e) => {
                if (!localStorage.getItem('cw_portfolio_user')) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              {options.map((opt) => (
                <MenuItem key={opt} value={opt}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      })}
</Box>

      {/* Fetch Button */}
      <Button variant="contained" fullWidth color="primary" onClick={fetchRecommendations} disabled={loading}>
        {loading ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Fetch Recommendations"}
      </Button>

      {/* Error Message */}
      {error && <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>{error}</Typography>}

      {/* Display recommendations */}
      {data && data.length > 0 && (
    <TableContainer component={Paper} sx={{ mt: 4, borderRadius: 2, boxShadow: 3 }}>
    <Table>
        <TableHead>
            <TableRow sx={{ backgroundColor: "#1976d2" }}>
                <TableCell sx={{ color: "#fff", fontWeight: "bold", whiteSpace: "nowrap" }}>Symbol</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold", whiteSpace: "nowrap" }}>Name</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold", whiteSpace: "nowrap" }}>{returnLabel}</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold", whiteSpace: "nowrap" }}>Market Cap</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold", whiteSpace: "nowrap" }}>Risk Level</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map((rec, index) => (
                <TableRow key={index} sx={{ "&:nth-of-type(even)": { backgroundColor: "#f0f0f0" } }}>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>{rec.symbol}</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>{rec.name}</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {rec.selected_returns !== null ? `${(rec.selected_returns * 100).toFixed(2)}%` : "N/A"}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>{rec.market_cap_category}</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>{rec.risk_level}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
</TableContainer>

)}

    </Box>
          
        </div>
      )} 
     
      
    </div>
  );
};

export default CustomPortfolio;
