export const updateProfile = (userData) => {
    console.log("UPDATE API CALL INITIATE");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", userData.token);
    // myHeaders.append("Token",userData.Token);
    var raw = JSON.stringify({
        user_id: userData.id,
        full_name: userData.name,
        portfolio_name: userData.portfolioname,
        country: userData.country,
    });
    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    return fetch(`${process.env.REACT_APP_API_URL}/profileupdate`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const updateAccount = (userData) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", userData.token);

    const bodyContent = new FormData();
    bodyContent.append("user_id", userData.user_id);
    bodyContent.append("full_name", userData.fullName);
    bodyContent.append("photo", userData.profilePhoto);
    bodyContent.append("about", userData.about);
    bodyContent.append("youtube", userData.youtube);
    bodyContent.append("twitter", userData.twitter);
    bodyContent.append("telegram", userData.telegram);
    bodyContent.append("instagram", userData.instagram);
    bodyContent.append("wallet_address", userData.wallet_address);

    return fetch(`${process.env.REACT_APP_API_URL}/accountupdate`, {
        method: "POST",
        body: bodyContent,
        headers: myHeaders,
    })
    .then((res) => {
        return res.json();
    })
    .catch((err) => console.log(err));
};

export const updateWatchlist = (userData) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", userData.token);

    const bodyContent = new FormData();
    bodyContent.append("user_id", userData.user_id);
    bodyContent.append("portfolio_id", userData.portfolio_id);
    bodyContent.append("action", userData.action);

    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/updateWatchlist`, {
        method: "POST",
        body: bodyContent,
        headers: myHeaders,
    })
    .then((res) => {
        return res.json();
    })
    .catch((err) => console.log(err));
};

export const updateCryptoWatchlist = (userData) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", userData.token);

    const bodyContent = new FormData();
    bodyContent.append("user_id", userData.user_id);
    bodyContent.append("slug", userData.slug);
    bodyContent.append("action", userData.action);

    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/updateCryptoWatchlist`, {
        method: "POST",
        body: bodyContent,
        headers: myHeaders,
    })
    .then((res) => {
        return res.json();
    })
    .catch((err) => console.log(err));
};

export const updatePrivacy = (userData) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", userData.token);

    const bodyContent = new FormData();
    bodyContent.append("user_id", userData.user_id);
    bodyContent.append("portfolio_type_id", userData.portfolio_type_id);
    bodyContent.append("action", userData.action);

    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/updatePrivacy`, {
        method: "POST",
        body: bodyContent,
        headers: myHeaders,
    })
    .then((res) => {
        return res.json();
    })
    .catch((err) => console.log(err));
};

export const storeReferrerData = (referrerId, refereeId, state) => {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const bodyContent = JSON.stringify({
        referrer_id: referrerId,
        user_id: refereeId,
        state: state,
    });

    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/storeReferrerData`, {
        method: "POST",
        headers: myHeaders,
        body: bodyContent,
    })
    .then((res) => {
        if (!res.ok) {
            throw new Error("Network response was not ok");
        }
        return res.json();
    })
    .then((data) => {
        console.log("STORE REFERRER DATA API SUCCESS", data);
        return data;
    })
    .catch((err) => {
        console.error("STORE REFERRER DATA API FAILED", err);
    });
};

export const getUserAccessPermission = async (data) => {
    const myHeaders = new Headers({
        "Content-Type": "application/json",
    });
    myHeaders.append("Authorization", data.token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `https://crowdwisdom.live/wp-json/portfolio/v1/get_user_access/?user_id=${data.user_id}&feature=${data.feature}`,
            requestOptions
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        return result.data;
    } catch (error) {
        console.error("Error fetching user rewards:", error);
        throw error;
    }
};

export const getUserTotalView = async (data) => {
    const myHeaders = new Headers({
        "Content-Type": "application/json",
    });
    myHeaders.append("Authorization", data.token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            `https://crowdwisdom.live/wp-json/portfolio/v1/get_total_user_views/?user_id=${data.user_id}&column=${data.column}`,
            requestOptions
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        return result.data;
    } catch (error) {
        console.error("Error fetching user rewards:", error);
        throw error;
    }
};

export const getAlertCountAPI = (userId) => {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    return fetch(`https://crowdwisdom.live/wp-json/api/v1/crypto_alerts`, {
        method: "POST", // Use POST instead of GET
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({user_id: userId}), // Send user_id in the body
    })
    .then((res) => {
        if (!res.ok) {
            throw new Error("Network response was not ok");
        }
        return res.json();
    })
    .then((data) => {
        return data;
    })
    .catch((err) => {
        console.error("Notification retreive API FAILED", err);
    });
};

export const getTop7DayPerformerAPI = (userData) => {
    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/get_seven_day_performer?user_id=${userData.user_id}&order=DESC&limit=1`, {
        method: "GET",
        headers: {
            Authorization: userData.token,
            "Content-Type": "application/json",
        },
    })
    .then((response) => response.json())
    .catch((err) => console.error("Error:", err));
};


export const getTop7DayPerformerGainerLooserAPI = (userData) => {
    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/get_seven_day_performer_gainer_looser?user_id=${userData.user_id}`, {
        method: "GET",
        headers: {
            Authorization: userData.token,
            "Content-Type": "application/json",
        },
    })
    .then((response) => response.json())
    .catch((err) => console.error("Error:", err));
};
